.operating-container {
    .icon-expand {
        display: none;
    }
}

.pha-chart-card {
    // max-height: 0vh ;
}

.pump_health_analytics-container,
.pump-health-container {
    width: 100%;
    #pha-14 {
        md-card {
            height: auto;
        }
    }
    md-card {
        max-height: 50vh;
        table {
            width: 100%;
        }
        md-toolbar {
            // background-color: $black;
            text-align: center;
            // text-transform: capitalize;
        }
    }
}

table.pvtTable tr th {
    background-color: #cdcdcd;
    border: 1px solid #000;
    font-size: 8pt;
    padding: 5px;
    font-weight: 500;
}

.pvtVal {
    font-size: 14px !important;
}

md-icon {
    margin: 8px 0 0 8px;
}

.pscardTitle {
    font-size: 1.0em !important;
    text-align: center !important;
    max-width: 100% !important;
}

.table {
    margin-bottom: 1px !important;
}

table>thead>tr>th {
    text-align: center;
}

.pump-row {
    text-transform: uppercase;
}