.view-container {
  .md-dialog-container {
    z-index: -80;
  }
  width: 100%;
  .bg-blue {
    background-color: $gaugeblue;
  }
  .bg-green {
    background-color: $gaugegreen;
  }
  .bg-darkgreen {
    background-color: $gaugedarkgreen;
  }
  .bg-orange {
    background-color: $gaugeorange;
  }
  .bg-red {
    background-color: $gaugered;
  }
  .bg-yellow {
    background-color: $gaugeyellow;
  }
  .bg-purple {
    background-color: $gaugepurple;
  }
  .bg-blue {
    background-color: $gaugeblue;
  }
  .hide {
    display: none !important;
  }
}

.draglayer {
  margin-top: -20px !important;
}

td.pvtTotal.rowTotal {
  display: none;
}

td.pvtTotal.colTotal {
  display: none;
}

th.pvtTotalLabel {
  display: none;
}

td.pvtGrandTotal {
  display: none;
}

// .gauge {
//     text-align: center;
//     max-height: 20vh;
//     height: 20vh;
//     // height: 100px;
//     padding: 0;
// }
.todayschart {
  // display: none;
  width: 80vw;
  height: 80vh;
}

.gc {
  max-height: 20vh;
  height: 50%;
  background-color: $darkblue;
}

.system-efficiency-container {
  // max-height: 870px;
  .system-efficiency-heading {
    height: 40px;
    margin: 0;
    background-color: $blue;
    h2 {
      text-align: center;
      background-color: $blue;
      color: white;
      font-size: 20px;
      margin: 0; // padding-top: 8px;
      letter-spacing: 0.4px;
    }
  }

  .wet-well-button {
    padding-left: 10px;
  }
}

.dashboard-container {
  z-index: 3 !important;
  width: 100%;
  overflow-y: scroll !important;
  padding-top: 42px;
  md-content {
    background-color: $bg-grey;
  }
  .dashboard-charts-container {
    margin: 0; // height: auto;
  }
  .chart-gauge {
    width: 100%; // margin: 100px auto
  }
  #FlowValue-gague {
    .chart-filled {
      fill: rgb(31, 119, 180);
    }
  }
  #SpeedValue-gague,
  #SuctionValue-gague {
    .chart-filled {
      fill: rgb(44, 160, 44);
    }
  }
  #TanklevelValue-gague {
    .chart-filled {
      fill: rgb(44, 160, 44);
    }
  }
  #ValveValue-gague,
  #Well_LevelValue-gague {
    .chart-filled {
      fill: $gaugeorange;
    }
  }
  #PressureValue-gague {
    .chart-filled {
      fill: rgb(255, 127, 14);
    }
  }
  #HeadValue-gague {
    .chart-filled {
      fill: rgb(214, 39, 40);
    }
  }
  #CostValue-gague {
    .chart-filled {
      fill: rgb(148, 103, 189);
    }
  }
  .chart-filled {
    fill: steelblue;
  }
  .chart-empty {
    fill: #dedede;
  }
  .needle,
  .needle-center {
    fill: #464a4f;
  }
  svg {
    font: 10px sans-serif;
  }
  g.c3-chart-arcs {
    width: 70%;
    position: relative;
  }
  text.c3-gauge-value {
    font-size: 2em !important;
    display: none;
  }
  .nv-legend-symbol {
    display: none !important;
  }
  .legend-color-guide {
    display: none !important;
    div {
      background-color: none !important;
    }
  }
  .dashboard-gauge-sync-container {
    .md-active {
      background-color: $darkblue;
      color: $white;
    }
    md-tabs-canvas {
      background-color: #eef0f3;
      border: none;
    }
    // md-tab-content {
    //     height: 33.3333333vh;
    // }
    .dashboard-gauge-container {
      height: 33.3333333vh;
      text-align: center;
      .gauges {
        text-align: center;
        height: 25vh;
        .gauge-view {
          span {
            display: block;
            text-align: center;
            line-height: 2px;
            padding: 10%;
          }
          max-height: 5vh;
        }
      }
    }
    .gauge {
      // margin-bottom: 20px;
      // max-height: 14vh;
      text-align: center;
      color: $white;
      height: 17vh;
      .needle,
      .needle-center {
        fill: $white;
      }
      span:first-child {
        line-height: 10%;
        padding: 2%;
      }
      .gauge-label {
        margin-bottom: 18px;
        display: block;
        text-align: center; // line-height: 1.2px;
        // margin-bottom: -10px;
        // font-size: 1.2vmin;
        // font-weight: 400;
        span {
          display: inline-block;
          text-align: center; // line-height: 8px;
          padding: 0;
          height: 0;
          font-weight: bolder;
          width: 33%;
          &.left {
            text-align: left;
            text-transform: uppercase;
            font-size: 1.2vmin;
            font-weight: 600;
          }
          &.center {
            text-align: center;
            font-size: 1.3vmin;
            font-weight: 600;
          }
          &.right {
            text-align: right;
            font-size: 1.2vmin;
            font-weight: 700;
          }
        }
      }
    }
    .gauge-units {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: 400; // color: $darkblue;
      // font-size: 1.1vmin;
      //font-weight: bold;
      padding: 15% 0 15% 0; // margin-top: -10px;
      color: $white; //text-shadow: 0.25px 0.25px 0.25px #fff;
    }
  }
  md-card {
    md-icon {
      color: white;
      float: left;
    }
  }
  // md-card {
  //     overflow: hidden;
  //     color: $primary;
  //     // height: 100%;
  //     margin-bottom: 0;
  //     td {
  //         // text-align: center; !important;
  //         // padding-left: 15% !important;
  //         // word-wrap: break-word;
  //         height: 3vh !important;
  //     }
  //     .rotate {
  //         border: none !important;
  //         height: 50px;
  //         /* Safari */
  //         -webkit-transform: rotate(45deg);
  //         /* Firefox */
  //         -moz-transform: rotate(45deg);
  //         /* IE */
  //         -ms-transform: rotate(45deg);
  //         /* Opera */
  //         -o-transform: rotate(45deg);
  //         /* Internet Explorer */
  //         // filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=3);
  //     }
  //     .card-heading {
  //         height: 20%;
  //         h3 {
  //             height: auto;
  //             margin: 0;
  //             padding: 0.8vmin 0;
  //             text-align: center;
  //             color: $white;
  //             // text-transform: uppercase;
  //             font-weight: bolder;
  //             font-size: calc(0.35rem + 1.45vmin);
  //             padding: 0.5vmin;
  //             text-transform: capitalize;
  //         }
  //     }
  //     .card-heading.blue {
  //         background-color: $secondaryblue;
  //     }
  //     .card-heading.dark-blue {
  //         background-color: $darkblue;
  //     }
  //     .card-heading.orange {
  //         background-color: $orange;
  //     }
  //     md-icon {
  //         float: left;
  //         // padding: 7px 10px;
  //         color: white;
  //     }
  //     td.table-heading {
  //         background-color: $blue !important;
  //         color: white;
  //         text-transform: uppercase;
  //         font-style: 1em;
  //         max-height: 8vh;
  //     }
  // }
}

#history-view-system-efficiency h2 {
  background-color: $blue;
  color: $white;
  text-align: center;
  margin: 0;
}

#myStaticDialog-sc-0 .js-plotly-plot,
#myStaticDialog-sc-1 .js-plotly-plot,
#myStaticDialog-sc-2 .js-plotly-plot {
  overflow: hidden;
  .plotly {
    margin-top: 25px;
  }
}

// @import url(https://fonts.googleapis.com/css?family=Rokkitt);
// * {
//   box-sizing: border-box;
// }
// body {
//   background: #e5e5e5;
// }
// ul {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   counter-reset: stairs 13;
//   padding: 0;
// }
// li {
//   clear: both;
//   width: 18px;
//   height: 18px;
//   border-radius: 50%;
//   margin:2.5px 14px;
//   background: #eaeaea;
//   position: relative;
//   counter-increment: stairs -1;
//   padding: 11px;
//   cursor: pointer;
//   display: inline-block;
//   box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 -1px 0 rgba(0, 0, 0, 0.3), 0 0 5px rgba(0, 0, 0, 0.3);
// }
// li:active i:after {
//   background: #f0cb00;
// }
// li i {
//   width: 100%;
//   height: 100%;
//   display: block;
//   border-radius: inherit;
//   background: linear-gradient(#dadada, #fafafa);
//   position: relative;
// }
// li i:after {
//   position: absolute;
//   width: 6px;
//   height: 6px;
//   background: #969696;
//   left: 50%;
//   top: 50%;
//   margin: -3px 0 0 -3px;
//   content: "";
//   border-radius: inherit;
//   box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
// }
// li:after {
//   content: "";
//   position: absolute;
//   left: -3px;
//   top: -3px;
//   bottom: -3px;
//   right: -3px;
//   z-index: -1;
//   background: linear-gradient(#c3c3c3, #f4f4f4);
//   border-radius: inherit;
//   box-shadow: inset 0 1px 1px rgba(80, 80, 80, 0.1);
// }
// glowing buttons
.button-on {
  background-color: #089201;
  -webkit-border-radius: 10px;
  border-radius: 50%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 12px;
  text-transform: capitalize;
  /*   padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  width: 25px;
  height: 25px;
  padding: 2px 1px;
  margin: 5px 10px;
}

// @-webkit-keyframes glowing {
//   0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
// }
// @-moz-keyframes glowing {
//   0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
// }
// @-o-keyframes glowing {
//   0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
// }
@keyframes glowing {
  0% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201;
  }
  50% {
    background-color: #089201;
    box-shadow: 0 0 20px #089201;
  }
  100% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201;
  }
}

.button-on {
  -webkit-animation: glowing 2000ms infinite;
  -moz-animation: glowing 2000ms infinite;
  -o-animation: glowing 2000ms infinite;
  animation: glowing 2000ms infinite;
}

// pump panel text
// li:before {
// // content: counter(stairs);
// text-align: center;
// position: absolute;
// display: block;
// font-family: 'Rokkitt', serif;
// color: #999;
// font-size: 18px;
// top: 100%;
// margin: 5px -30%;
// transform: scaleY(1.15) scaleX(0.85);
// }
// default odometer
.odometer.odometer-auto-theme,
.odometer.odometer-theme-minimal {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  transform: translateZ(0);
}

.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-minimal
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.flow-prediction-container {
  max-height: 970px;
  .flow-prediction-heading {
    height: 40px;
    margin: 0;
    background-color: $blue;
    h2 {
      text-align: center;
      background-color: $blue;
      color: white;
      font-size: 20px;
      letter-spacing: 1px;
      margin: 0;
      padding-top: 10px;
    }
  }
}

.md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.md-whiteframe-12dp {
  box-shadow: 0px 15px 16px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.inner-wrapper {
  margin-left: 1.3px;
}

// table.md-table thead.md-head>tr.md-row {
//     height: 30px !important;
//     text-transform: capitalize !important;
// }
// table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2) {
//     padding: 0 7px 0 0 !important;
// }
// table.md-table th.md-column {
//     color: black !important;
//     font-weight: 600 !important;
// }
// .rta-card {
//     height: auto;
//     min-height: 2vh;
// }
.notificationToast {
  md-toast._md-top._md-center,
  md-toast._md-bottom._md-center {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
.status-toast.md-default-theme .md-toast-content,
.status-toast .md-toast-content {
  background-color: $red;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  text-align: center;
  margin-top: 0;
  padding: 0;
  max-height: 43px !important;
}

.rta-info {
  font-size: calc(0.35rem + 1.45vmin) !important;
  float: right;
}

.rta-load {
  font-size: larger;
  font-weight: 700;
}

.info-bar {
  background-color: $darkblue !important;
  min-height: 45px !important;
}

.info-h1 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  padding-top: 12px;
}

.info-data {
  font-size: 13px;
  color: #eef0f3;
  margin: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 18px;
  img {
    height: 30px;
    width: 50px;
    vertical-align: sub;
    padding-right: 5px;
  }
  .info-heading-1 {
    margin-top: 0;
    text-align: center;
    // text-decoration: underline;
    font-size: large;
    font-weight: 700;
  }
}

.info-toolbar {
  min-height: 0;
}

.info-content {
  background-color: $darkblue !important;
  .rta-update {
    color: $white;
    position: fixed;
    bottom: 0;
    left: 5%;
    font-weight: 500;
  }
}

.info-heading {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
  font-size: large;
  font-weight: 700;
}

.close-info {
  // background-color: $orange;
  background-image: linear-gradient(
    to right,
    #6990fa,
    #5e81e0,
    #5372c6,
    #4863ad,
    #3e5594
  );
  width: 95%;
  color: $white;
  &:hover {
    background-color: $lightred !important;
    color: $white !important;
  }
}

.dashboard-card {
  background-color: $white;
  .first-card {
    text-align: center;
    height: 149px;
    .flow-text {
      background-color: $darkblue;
      color: $white;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder;
    }
    .flow-unit {
      background-color: $darkblue;
      color: $white;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder;
      display: inline-flex;
      text-align: center;
      md-switch {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: fit-content;
      }
      md-switch.md-checked.md-warn .md-thumb {
        background-color: $lightblue;
      }
      md-switch.md-checked.md-warn .md-bar {
        background-color: rgba(73, 159, 212, 0.5);
      }
      :focus {
        outline: none;
      }
    }
    .pressure-text,
    .pressure-unit {
      color: $white;
      background-color: $orange;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder;
    }
    .flow-value,
    .pressure-value {
      font-size: 56px;
      letter-spacing: 2px;
      color: #4d5322;
      .odometer.odometer-theme-minimal {
        .odometer-value {
          font-size: calc(1.4rem + 5.45vmin);
        }
      }
    }
    .value-box {
      background-color: $blue-tint;
    }
  }
  .second-card {
    // text-align: center;
    // max-height: fit-content;
    height: 149px;
    overflow: scroll;
    .second-card-heading {
      text-align: center;
      color: $white;
      font-weight: bolder;
      font-size: 18px;
      // text-transform: capitalize;
      background-color: $darkblue;
      margin: 0;
      padding: 4px 0;
    }
    .value-box {
      background-color: $lightblue;
      color: $white;
      text-align: center;
      .dmg-value {
        font-size: 56px;
        letter-spacing: 2px;
        margin: 0 10%;
      }
      .dmg-unit {
        font-size: calc(0.35rem + 1.75vmin);
        position: relative;
        top: 20%;
      }
    }
    .pump-btn {
      background-color: $lightblue;
      -webkit-border-radius: 10px;
      border-radius: 50%;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      // font-family: Arial;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      /*   padding: 5px 10px; */
      text-align: center;
      text-decoration: none;
      width: 30px;
      height: 30px;
      // padding: 2px 1px;
      margin: 5px 8px;
    }
    .pump-btn-on {
      background-color: $red;
      // transform: translateZ(0);
      backface-visibility: hidden;
      // will-change: transform, opacity;
    }
    // @keyframes glowing {
    //     0% {
    //         background-color: #089201;
    //         box-shadow: 0 0 1.5px #089201;
    //         // transform: translateZ(0);
    //     }
    //     50% {
    //         background-color: #089201;
    //         box-shadow: 0 0 20px #089201;
    //         // transform: translateZ(0);
    //     }
    //     100% {
    //         background-color: #089201;
    //         box-shadow: 0 0 1.5px #089201;
    //         // transform: translateZ(0);
    //     }
    // }
    // .pump-btn-on {
    //     -webkit-animation: glowing 2000ms infinite;
    //     -moz-animation: glowing 2000ms infinite;
    //     -o-animation: glowing 2000ms infinite;
    //     animation: glowing 2000ms infinite;
    // }
    .btn-flex {
      // margin-left: 6%;
    }
    .second-card-footer {
      background-color: $darkblue;
      color: $white;
      padding: 6px 0;
      font-weight: 500;
    }
  }
  .third-card {
    // min-height: 2vh !important;
    // max-height: fit-content;
    max-height: 149px;
    overflow: scroll;
    background-color: transparent;
    .third-card-heading {
      height: auto;
      text-align: center;
      color: $white;
      font-weight: bolder;
      font-size: calc(0.35rem + 1.45vmin);
      text-transform: capitalize;
      background-color: $darkblue;
      margin: 0;
      padding: 4px 0;
      letter-spacing: 0.4px;
      .rta-info {
        font-size: calc(0.35rem + 1.45vmin) !important;
        float: right;
      }
    }
    .rta-box-long {
      background-color: $lightblue;
    }
    .rta-text {
      background-color: white;
      font-size: larger;
      font-weight: 700;
      max-height: 17vh;
      color: black;
      text-align: center;
      line-height: 123px;
      // margin-bottom: 1.5%;
      // height: auto;
    }
    .block-text {
      background-color: $logo-color3;
      font-size: 16px;
      font-weight: 500;
      color: white;
      padding: 1.5%;
      word-wrap: break-word;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    table.md-table:not(.md-row-select)
      th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 0;
    }
    table.md-table thead.md-head > tr.md-row {
      height: 24px !important;
      // text-transform: capitalize !important;
    }
    // table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2) {
    //     padding: 0 1% 0 0 !important;
    // }
    table.md-table th.md-column {
      color: white !important;
      font-weight: 700 !important;
      text-align: center !important;
      // padding:0 !important;
    }
    td.md-cell:nth-child(even),
    th.md-column:nth-child(even) {
      background-color: $orange !important;
      color: white !important;
      font-weight: 500;
      text-align: center;
      // text-transform: uppercase;
      padding: 0 !important;
      font-size: calc(0.3rem + 1.1vmin);
    }
    td.md-cell:nth-child(odd),
    th.md-column:nth-child(odd) {
      background-color: $lightblue !important;
      color: white !important;
      font-weight: 500;
      // text-transform: uppercase;
      font-size: calc(0.3rem + 1.1vmin);
      padding: 0 !important;
      letter-spacing: 0.4px;
    }
    table.md-table:not(.md-row-select) th.md-column:first-child {
      padding: 0;
    }
    table.md-table th.md-column:last-child {
      padding: 0;
    }
    .md-row .md-column,
    .md-row .md-cell {
      text-align: center !important;
      // padding: 0;
      // padding-left: 1%;
      font-size: 14px;
      letter-spacing: 0.8px;
    }
    table.md-table tbody.md-body > tr.md-row,
    table.md-table tfoot.md-foot > tr.md-row {
      height: 21px !important;
      text-transform: capitalize;
      text-align: left;
    }
  }
}

md-icon {
  color: white;
}

.nogauge-card {
  .error-content {
    display: inherit;
    .error-icon {
      color: red;
      font-size: 20px;
    }
    .error-text {
      font-size: larger;
      font-weight: 700;
      color: black;
      margin-left: 5px;
    }
  }
}

.se-load {
  display: grid;
  margin: 8%;
  .text {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
  }
  .img {
    width: 80px;
    height: auto;
    margin-left: 12px;
  }
}

.se-content {
  padding: 120px 0 120px 0;
  .error-icon {
    color: red;
    font-size: 20px;
  }
  .error-text {
    font-size: larger;
    font-weight: 700;
    color: black;
    margin-left: 5px;
  }
}

.frame-tab,
.frame-expand,
.frame-history {
  background: url("https://d2a5hmbghmbgtd.cloudfront.net/webfonts/atlas_spinner.svg")
    center center no-repeat;
}

.day-content {
  margin: 0 8px;
  .dash-on-peak {
    color: $white;
    font-weight: 500;
    padding-left: 8px;
    font-size: 19px;
  }
  .dash-off-peak {
    color: $white;
    font-weight: 500;
    padding-left: 8px;
    font-size: 19px;
  }
  .day-forecast-header {
    min-height: 40px !important;
    max-height: 40px;
    border-radius: 4px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $blue !important;
    .day-forecast-title {
      font-size: calc(0.35rem + 1.85vmin);
      color: $white;
      font-weight: 500;

      .ss-value {
        background-color: #f44336;
        border-radius: 4px;
        letter-spacing: 1px;
        padding: 2px;
      }
      .ss-unit {
        font-size: calc(0.35rem + 1vmin);
      }
      // text-shadow: 2px 2px 5px Black;
    }
    .day-forecast-icon {
      text-align: right;
      display: block !important;
      color: $white;
      .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
          -webkit-transform 0.6s ease-in-out;
        &:focus {
          outline: none;
        }
      }
    }
    &:focus {
      outline: none;
    }
  }
  .content-block {
    padding: 0;
    md-card {
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .ecard-0 {
      margin-right: 8px;
      @media (max-width: 959px) and (min-width: 600px) {
        margin: 0 !important;
      }
    }
    .ecard-1 {
      margin-left: 8px;
      @media (max-width: 959px) and (min-width: 600px) {
        margin: 0 !important;
      }
    }
  }
  .day-card-param {
    font-size: calc(0.35rem + 1.65vmin);
    color: $white;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-weight: bolder;
    letter-spacing: 0.3px;
    md-icon {
      margin: auto;
      padding-left: 8px;
    }
  }
  .day-card-value {
    font-size: calc(1rem + 3vh); // letter-spacing: 2px;
    text-align: center;
  }
  .day-card-unit {
    color: $white;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.35vmin);
    font-weight: bolder;
  }
  .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin);
  }
  .block-unit {
    color: $white;
    text-align: center;
    margin: 0;
    padding: 0.45vmin 0;
    font-size: calc(0.35rem + 1.35vmin);
    font-weight: bolder;
  }
  .dd-0 {
    background-color: steelblue;
  }
  .dd-1 {
    background-color: $orange;
  }
}

.toggled {
  transform: rotate(0deg) !important;
}

.pump-aging-content {
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  display: flex;
  md-card {
    margin: 0;
  }
  margin: 0 8px;
  .pump-aging-header-border {
    border-bottom: 1px $white solid;
  }
  .pump-aging-header {
    min-height: 40px !important;
    max-height: 40px;
    border-radius: 4px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $blue !important;
    md-icon {
      margin: 0 0 0 8px;
    }
    &:focus {
      outline: none;
    }
    .pump-aging-title {
      font-size: calc(0.35rem + 1.85vmin);
      color: $white;
      font-weight: 500;
      img {
        height: 30px;
        width: 50px;
        vertical-align: sub;
      }
      &:focus {
        outline: none;
      }
      .pump-name-logo {
        padding-left: 15px;
      }
      .flip-switch {
        padding-left: 10px;
        md-switch {
          margin: 0;
          width: fit-content;
          padding: 0 10px;
          height: fit-content;
        }
        md-switch.md-checked .md-thumb {
          background-color: $secondary;
        }
        md-switch.md-checked .md-bar {
          background-color: #ff990092;
        }
      }
    }
    .aging-icon {
      text-align: right;
      display: block !important;
      color: $white;
      &:focus {
        outline: none;
      }
      .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
          -webkit-transform 0.6s ease-in-out;
      }
    }
  }
  .pump-aging-card {
    background-color: $darkblue;
    display: block;

    @media (max-width: 1920px) and (min-width: 1280px) {
      flex: 1 0 14%;
    }
    .pump-name-header {
      background-color: $logo-color2;
      font-size: calc(0.35rem + 1.45vmin);
      color: $white;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .color-green {
      background-color: green;
      color: $white;
    }
    .color-red {
      background-color: red;
      color: $white;
    }
    .color-grey {
      background-color: grey;
      color: $white;
    }
    .color-yellow {
      background-color: #ffd900;
      color: $black;
    }

    .name {
      margin-right: 10px;
      font-weight: 500;
      font-size: 16px;
    }
    .pump-value {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
  .pump-age-block {
    background-color: $white;
    .age-value {
      font-size: calc(1rem + 1.5vw);
      letter-spacing: 2px;
      font-weight: 500;
    }
    .counter-multi {
      vertical-align: sub;
      font-size: 16px;
      font-weight: 500;
    }
    .pump-age-text {
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .age-pump-run {
    text-align: center;
    background-color: $orange;
    color: $white;
    font-weight: 500;
    text-transform: capitalize;
  }
  .age-pump-value {
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
    background-color: $white;
    hr {
      border-top: 2px solid black;
      width: 12px;
    }
    .age-value {
      font-size: calc(1rem + 1vw);
      letter-spacing: 2px;
      font-weight: 400;
    }
    .age-year {
      vertical-align: sub;
      text-transform: lowercase;
    }
  }

  .flip-container {
    perspective: 1000px;
    background-color: $darkblue;
  }

  .flip-back {
    transform: rotateY(180deg);
    &:hover {
      transform: rotateY(0deg);
    }
  }

  .flip-front {
    transform: rotateY(0deg);
    &:hover {
      transform: rotateY(180deg);
    }
  }

  .flip-container,
  .front,
  .back {
    width: 100%;
    height: 115px;
  }

  .flipper {
    transition: 0.9s;
    transform-style: preserve-3d;
    position: relative;
  }

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
    background-color: #9e9e9e;
    color: white;
  }
  .back-on {
    background-color: $logo-color3;
  }
}

.demand-planner-container {
  .demand-planner-heading {
    min-height: 40px !important;
    max-height: 40px;
    border-radius: 4px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $blue !important;
    md-icon {
      margin: 0 0 0 8px;
    }
    &:focus {
      outline: none;
    }
    .title {
      font-size: calc(0.35rem + 1.85vmin);
      color: $white;
      font-weight: 500;
      img {
        height: 30px;
        width: 50px;
        vertical-align: sub;
      }
      &:focus {
        outline: none;
      }
      .pump-name-logo {
        padding-left: 15px;
      }
    }
    .switch-icon {
      text-align: right;
      display: block !important;
      color: $white;
      &:focus {
        outline: none;
      }
      .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out,
          -webkit-transform 0.6s ease-in-out;
      }
    }
  }
}

// @keyframes glow {
//   0% {
//     // stroke: #089201;
//     stroke-opacity: 0.2;
//     -webkit-filter: drop-shadow(-0.75px 4px 6px);
//     filter: drop-shadow(-0.75px 4px 6px);
//   }
//   25% {
//     // stroke: #089201;
//     stroke-opacity: 0.3;
//     -webkit-filter: drop-shadow(-0.75px 4px 6px);
//     filter: drop-shadow(-0.75px 4px 6px);
//   }
//   50% {
//     // stroke: #089201;
//     stroke-opacity: 0.5;
//     -webkit-filter: drop-shadow(-0.75px 4px 6px);
//     filter: drop-shadow(-0.75px 4px 6px);
//   }
//   75% {
//     // stroke: #089201;
//     stroke-opacity: 0.6;
//     -webkit-filter: drop-shadow(-0.75px 4px 6px);
//     filter: drop-shadow(-0.75px 4px 6px);
//   }
//   100% {
//     // stroke: #089201;
//     stroke-opacity: 0.7;
//     -webkit-filter: drop-shadow(-0.75px 4px 6px);
//     filter: drop-shadow(-0.75px 4px 6px);
//   }
// }

.blink {
  -webkit-animation: glow 2000ms infinite;
  -moz-animation: glow 2000ms infinite;
  -o-animation: glow 2000ms infinite;
  animation: glow 2000ms infinite;
  backface-visibility: hidden;
}

@keyframes glow {
  0% {
    stroke-opacity: 0.2;
  }
  25% {
    stroke-opacity: 0.3;
  }
  50% {
    stroke-opacity: 0.5;
  }
  75% {
    stroke-opacity: 0.6;
  }
  100% {
    stroke-opacity: 0.7;
  }
}

.scatterlayer {
  .points {
    .point {
      -webkit-animation: glow 2000ms infinite;
      -moz-animation: glow 2000ms infinite;
      -o-animation: glow 2000ms infinite;
      animation: glow 2000ms infinite;
      backface-visibility: hidden;
    }
  }
}
// .cube {
// 	position: relative;
// 	width: 100px;
//     transform-style: preserve-3d;
// }

// .cube div {
// 	position: absolute;
// 	width: 100px;
//     height: 200px;
//     background-color: red;
// }

// .back {
// 	transform: translateZ(-100px) rotateY(180deg);
// }
// .right {
// 	transform: rotateY(-270deg) translateX(100px);
// 	transform-origin: top right;
// }
// .left {
// 	transform: rotateY(270deg) translateX(-100px);
// 	transform-origin: center left;
// }
// .top {
// 	transform: rotateX(-90deg) translateY(-100px);
// 	transform-origin: top center;
// }
// .bottom {
// 	transform: rotateX(90deg) translateY(100px);
// 	transform-origin: bottom center;
// }
// .front {
// 	transform: translateZ(100px);
// }

// .wrap {
// 	/* no more perspective */
// 	perspective: none;
// 	perspective-origin: 0 0;
// }

// @keyframes spin {
// 	from { transform: rotateY(0); }
// 	to { transform: rotateY(360deg); }
// }

// .cube {
// 	animation: spin 60s infinite linear;
// }

/* entire container, keeps perspective */
// .flip-container {
//     perspective: 1000px;
//     background-color:$darkblue;
// }

//     .flip-back{
//         transform: rotateY(180deg);
//         &:hover{
//             transform: rotateY(0deg);
//             }
//     }

//     .flip-front {
//         transform: rotateY(0deg);
//         &:hover{
//             transform: rotateY(180deg);
//             }
//     }

// .flip-container, .front, .back {
// 	width: 100%;
// 	height: 115px;
// }

// .flipper {
// 	transition: 0.9s;
// 	transform-style: preserve-3d;
// 	position: relative;
// }

// .front, .back {
// 	backface-visibility: hidden;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

// .front {
// 	transform: rotateY(0deg);
// }

// .back {
//     transform: rotateY(180deg);
//     background-color:$logo-color3;
//     color:white;

// }
.pump-agis-nav-bar {
  .md-nav-bar md-nav-ink-bar {
    background-color: $logo-color2;
  }
  .md-nav-bar .md-button.md-active {
    color: $logo-color2;
  }
  .note-btn {
    position: absolute;
    right: 47%;
    z-index: 100;
    margin: 0;
    padding: 0;
    background-color: $logo-color;
    color: white;
    min-height: unset;
    min-width: unset;
    line-height: unset;
    &:hover {
      background-color: $logo-color2;
    }
    md-icon {
      margin: 8px;
    }
  }
}

.rta-saving__block {
  padding-top: 10px;
  background: white;
  .rta-saving__block-title {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
  }
  .rta-saving__block-perc {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 4px;
  }
  .rta-saving__block-cost {
    font-weight: 500;
    font-size: 18px;
  }
}

.rta-trd__mark {
  color: grey;
  font-size: 9px;
  text-decoration: none;
  // margin-left: 3px;
}
