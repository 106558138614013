.ubr-container {
  .leaflet-popup-content {
    text-align: center !important;
  }

  .pop-name {
    text-transform: capitalize;
    font-weight: 600;
  }

  .leaflet-top {
    z-index: 0;
  }

  md-content {
    background-color: $bg-grey;
  }

  .popup_red_color {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: #b60909;

      a {
        color: white !important;
        font-weight: 500;
      }
    }
  }

  .popup_green_color {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: green;

      a {
        color: white !important;
        font-weight: 500;
      }
    }
  }

  .popup_grey_color {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: $bg-grey;

      a {
        color: $white !important;
        font-weight: 500;
      }
    }
  }

  .map-block {
    .map-card-1 {
      // margin-top: 10px;
      min-height: 65vh;
      overflow: hidden;
    }
    .map-card-2 {
      // margin-top: 10px;
      min-height: 48vh;
      overflow: hidden;
    }
  }

  .station-check {
    position: absolute;
    right: 4px;
    // top: 50px;
    z-index: 1;
    overflow-y: scroll;
    width: 10%;
    .system-check-header {
      padding: 2px 0;
      background-color: $darkblue;
      color: $white;
      font-weight: 500;
      font-size: calc(1rem + 1.45vmin);
      text-transform: uppercase;
      a {
        color: white;
        text-decoration: none;
      }
    }

    .site-grey {
      background-color: grey;
    }
    .site-green {
      background-color: #097a09;
    }
    .site-red {
      background-color: #b60909;
    }
    .check-name {
      width: 100%;
      .check-btn {
        width: stretch;
        -webkit-transition: ease-out 0.5s;
        -moz-transition: ease-out 0.5s;
        transition: ease-out 0.5s;
        box-shadow: inset 0 0 0 0 $logo-color2;
      }
      .ADD {
        box-shadow: inset 0 0 0 50px green;
      }
      .DELETE {
        box-shadow: inset 0 0 0 50px red;
      }
    }
  }

  @media (max-width: 1279px) and (min-width: 960px) {
    .station-check {
      width: 20%;
    }
  }

  @media (max-width: 959px) and (min-width: 600px) {
    .station-check {
      width: 20%;
    }
  }

  @media (max-width: 599px) {
    .station-check {
      width: 30%;
    }
  }

  .ubr-analysis {
    overflow:visible;
    .ubr-analysis-block {
      margin-top: 50px;
      img {
        height: 15px;
        width: 40px;
        vertical-align: baseline;
      }
      .ubr-param-name {
        padding-left: 4px;
      }
    }
  }

  .ubr-card-div {
    padding: 0 6px;
    background-color: gainsboro;
  }

  .uber-value-block {
    text-align: center;
    .ubr-counter-value {
      font-weight: 500;
    }

    .counter-value-green {
      // background-color:green;
      color: green;
    }
    .counter-value-red {
      // background-color:red;
      color: red;
    }
    .counter-value-grey {
      // background-color:red;
      color: $bg-grey;
    }
  }

  .ub-0 {
    background-color: $logo-color2;
  }

  .ub-1 {
    background-color: $logo-color2;
  }

  .ub-2 {
    background-color: $logo-color2;
  }

  .ub-3 {
    background-color: $logo-color2;
  }

  .ub-4 {
    background-color: $logo-color2;
  }
  .ub-5 {
    background-color: $logo-color2;
  }
  .ubr-param {
    color: $white;
    padding: 2px 0;
    font-size: calc(0.45rem + 1.25vmin);
    font-weight: 700;
    margin: 0;
    letter-spacing: 0.5px;
  }
  .ubr-unit {
    color: $white;
    margin: 0;
    padding: 2px 0;
    font-size: calc(0.45rem + 1.25vmin);
    letter-spacing: 0.5px;
    font-weight: 500;
    md-switch {
      margin: 0;
    }
  }

  .flow-unit {
    text-align: center;
    margin: 0;
    padding: 2px 0;
    font-size: calc(0.45rem + 1.25vmin);
    font-weight: bolder;
    display: inline-flex;
    text-align: center;
    letter-spacing: 0.5px;
    md-switch {
      margin: 0;
      width: fit-content;
      padding: 0 5px;
      height: fit-content;
    }
    md-switch.md-checked.md-warn .md-thumb {
      background-color: $lightblue;
    }
    md-switch.md-checked.md-warn .md-bar {
      background-color: rgba(73, 159, 212, 0.5);
    }
    :focus {
      outline: none;
    }
  }
  .ubr-short-links {
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    background-color: gainsboro;
    a {
      color: $logo-color2;
    }
  }
  .ubr-value {
    font-size: calc(1.4rem + 1.8vw);
    letter-spacing: 1px;
  }
  .ubr-value-0 {
    font-size: calc(1.4rem + 1.7vw);
    letter-spacing: 1px;
  }
  .ubr-value-1 {
    font-size: calc(1rem + 1.5vw);
    letter-spacing: 1px;
  }
  .ubr-gauge {
    span {
      line-height: 140px !important;
      font-size: 38px;
      font-weight: 500;
    }
    u {
      font-size: 20px !important;
      font-weight: 500 !important;
    }
    b {
      line-height: 85px !important;
      font-weight: 500 !important;
      text-transform: uppercase;
    }
  }
  canvas {
    height: 170px;
    width: 170px;
    overflow: hidden;
  }
  .ubr-card-gauge {
    max-height: 117.36px;
    position: relative;
  }
  .ubr-site-bar {
    padding: 2px 0;
    background-color: $darkblue;
    color: $white;
    font-weight: 500;
    font-size: calc(1rem + 1.45vmin);
    text-transform: uppercase;
    a {
      color: white;
      text-decoration: none;
    }
  }
  .ubr-site-bar-grey {
    background-color: grey;
  }
  .ubr-site-bar-green {
    background-color: #097a09;
  }
  .ubr-site-bar-red {
    background-color: #b60909;
  }
  .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin);
  }
  md-icon {
    vertical-align: text-top;
    color: red;
    margin: 0;
  }

  .good-start {
    position: absolute;
    font-weight: 500;
    font-size: calc(0.4rem + 8px);
    z-index: 10;
    bottom: 0;
    left: 4px;
  }
  .good-stop {
    z-index: 10;
    font-weight: 500;
    font-size: calc(0.4rem + 8px);
    position: absolute;
    bottom: 0;
    right: 4px;
    
  }
  .radar-button {
    position: absolute;
    // bottom: calc(45px + 120px);
    top:12px;
    right: 12px;
    width: 167px;
    margin: 0;
    color: $white !important;
    background-color: $secondaryblue !important;
    border-radius: 2px;
    z-index: 0;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
}
.ubr-dialog {
  padding: 4px;
  background-image: linear-gradient(to right top, #e6e8ee, #e6e7ec, #e6e6e9, #e6e6e7, #e5e5e5);
  .ubr-text {
    font-size: 16px;
    font-weight: 500;
    .add{
      color:green;
    }
    .delete{
      color:red;
    }
  }
  .text-top {
    padding-bottom: 10px;
  }
  .ubr-close {
    background-color: $red;
    transition: all 0.2s ease-in-out;
    color:$white;
    &:hover {
      background-color: $red;
      box-shadow: 0 5px 15px rgb(212,31,34);
    }
  }
  .ubr-update {
    background-color: $logo-color;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    color:$white;
    &:hover {
      background-color: $logo-color;
      box-shadow: 0 5px 15px rgb(73,100,174);
    }
  }
  md-select{
    padding: 10px 0;
    margin:0;
    .md-text{
      text-transform: uppercase;
    }
  }

  hr{
    margin:10px;
  }
  
}

.popup-dialog {
  width: 85% !important;
  height: 90% !important;
  .popup-dialog-headar {
    height: 50px;
    background-color: $darkblue;
    .header-text {
      font-size: 20px;
      font-weight: 500;
    }
    .popup-dialog-icon {
      img{
        margin: 5px;
        max-height: 40px;
        max-width: 40px;
      }
      
    }
  }
  .map-radar {
    height: 100%;
  }
  .popup-dialog-close {
    background-color: $lightblue;
    color: $white;
    font-weight: 500;
    font-size: 1em;
    float: right;
    &:hover {
      background-color: $red !important;
    }
  }
}
