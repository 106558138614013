* {
  font-family: "Roboto", sans-serif;
}

.shell {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: none;
  overflow-x: none;
  overflow-y: none;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "\201C""\201D""\2018""\2019";
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  /* basic modern patch */
  all: initial;
  all: unset;
  &.header {
    width: 100%;
    float: right;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

// .fullscreen-chart {
//     form {
//         margin: 5px 0 0 5px;
//     }
//     .demo-tab {
//         form {
//             margin: 0px 0 0 12px;
//         }
//     }
//     h2 {
//         margin-top: 10px;
//     }
//     .card-heading {
//         display: inline-flex;
//         position: absolute;
//         width: 100%;
//         img {
//             width: 3vw;
//             height: 3vw;
//             margin: 5px;
//             max-height: 40px;
//             max-width: 40px;
//             position: absolute;
//             top: 0vh;
//         }
//     }
// }

// .dashboard-container {
//     h2 {
//         margin-top: 10px;
//     }
//     .card-heading.dashboard {
//         img {
//             max-height: 40px;
//             max-width: 40px;
//             height: 4vh;
//             width: 4vw;
//             margin: 5px;
//         }
//     }
// }

// // media queries
// @media (-webkit-min-device-pixel-ratio: 2),
// (min-resolution: 192dpi) {
//     .chat-header {
//         h1 {
//             padding: 0.7vh 0 0 0;
//         }
//     }
//     .fullscreen-chart {
//         h2 {
//             margin-top: 10px;
//         }
//         .card-heading {
//             img {
//                 width: 3vw;
//                 height: 4vh;
//                 margin: 10px;
//             }
//         }
//     }
// }

// @media (-webkit-min-device-pixel-ratio: 1.25),
// (min-resolution: 120dpi) {
//     .fullscreen-chart {
//         h2 {
//             margin-top: 10px;
//         }
//         .card-heading {
//             img {
//                 width: 3vw;
//                 height: 4vh;
//                 margin: 10px;
//             }
//         }
//     }
// }

/* 1.3 dpr */

// @media (-webkit-min-device-pixel-ratio: 1.3),
// (min-resolution: 124.8dpi) {
//     .fullscreen-chart {
//         h2 {
//             margin-top: 10px;
//         }
//         .card-heading {
//             img {
//                 width: 3vw;
//                 height: 4vh;
//                 margin: 10px;
//             }
//         }
//     }
// }

// /* 1.5 dpr */

// @media (-webkit-min-device-pixel-ratio: 1.5),
// (min-resolution: 144dpi) {
//     .fullscreen-chart {
//         h2 {
//             margin-top: 10px;
//         }
//         .card-heading {
//             img {
//                 width: 4.5vw;
//                 height: auto;
//                 margin: 10px;
//             }
//         }
//     }
// }

.pumplist {
  height: auto;
  min-height: 20vh;
  display: block;
}

// cards on different pages
.foreground {
  // z-index: 81;
}

.background {
  // z-index: 78;
}

#myStaticDialog-sc-0,
#myStaticDialog-sc-1,
#myStaticDialog-sc-2 {
  width: 100vw !important;
  height: 100vh !important;
}

.md-dialog-container {
  // z-index: -80;
  md-dialog#report {
    md-dialog-content {
      padding: 0 20px;
      max-width: 80vw;
    }
    .header-left {
    }
    .header-title {
      text-align: right;
    }
  }
  md-radio-group {
    margin: 5px 0 0 10px;
  }
  .card-heading {
    background-color: $darkblue;
    position: relative;
    z-index: 99;
    //margin-bottom: 10px;
    max-height: 12vh;
    img {
      width: 4vh;
      height: auto;
      margin-left: 10px;
    }

    h2 {
      color: $white;
      text-align: center;
      margin: 0;
      font-size: calc(1.2rem + 1.55vmin);
      text-transform: capitalize;
    }
    button:hover {
      background-color: $red;
    }
    button {
      background-color: $lightblue;
      color: $white;
      font-weight: bold;
      font-size: 1.2em;
      float: right;
      margin: 8% 7%;
    }
    md-icon {
      margin: auto;
    }
  }
}

#pha-141 md-icon {
  display: none;
}

.large-chart {
  height: 90vh;
  md-icon {
    display: none;
  }
  md-card {
    height: 90vh;
    max-height: 90vh !important;
    iframe {
      height: 80vh !important;
      max-height: 80vh !important;
    }
  }
}

control-card {
  // z-index: 82;
  md-card {
    max-height: 40vh;
    md-input-container {
      margin-right: 20px;
      color: $white !important;
      padding: 0;
      margin: 0;
      float: right;
      md-select .md-select-value {
        height: 100%;
        border: none !important;
        margin-top: 2px;
        text-align: right;
        font-weight: 500;
        color: $white;
      }
    }
  }
}

.fade-out {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 0;
}

.fade-in {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 1;
}

.md-button.md-fab {
  background-color: none;
  widows: 22px;
}

.card-info {
  position: absolute;
  top: 0px;
  right: 0px;
  i {
    font-size: 1.5em;
    // text-align: center;
    // vertical-align: middle;
  }
}

.card-table {
  width: 100%;
}

md-card {
  .table-row {
    td {
      text-align: center;
    }
    .table-cell-heading {
      text-align: left;
    }
  }
  overflow-y: hidden;
  border-radius: 4px;
  //padding-bottom: 5px;
  md-content {
    border-radius: 4px;
    overflow-y: hidden;
    background-color: white;
  }
  md-toolbar {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    background-color: $blue;
    color: #eef0f3;
    text-align: center;
    min-height: 40px;
    h2 {
      margin: 9px 0 0 0;
      &.multi-pump-card-title {
        text-align: right;
        padding-right: 4px;
      }
    }
  }
  .card-heading {
    background-color: $blue;
    // margin-bottom: 10px;
  }
  #chart-container {
    width: 100%;
    //height: 30vh;
    .custom-controls {
      position: absolute;
      z-index: 1;
      padding-top: 4px;
      margin-left: 19vw;
      @media screen and (max-width: $break-sm-computer) {
        margin-left: 12%;
      }
      @media screen and (min-width: $break-lg-computer) {
        margin-left: 17%;
      }
      @media screen and (min-width: $break-xl-computer) {
        margin-left: 19%;
      }
      .material-icons {
        font-size: 16px;
      }
    }
  }
}

// end cards on different pages
// pumps
.led-box {
  height: 30px;
  width: 25%;
  margin: 0 0 20px 0;
  float: left;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 0;
}

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-gray {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #a6a6a6;
  border-radius: 50%;
  box-shadow: #e5e5e5 0 -1px 7px 1px, inset #006 0 -1px 9px, #e5e5e5 0 2px 14px;
}

// pump end
body {
  max-width: 100vw;
  overflow: hidden;
}

div.pump {
  position: absolute;
  left: 0;
  width: 30%;
  animation: translate 7s infinite linear;
  img {
    position: absolute;
    animation: upDown 0.7s alternate infinite ease-in-out;
    width: 5%;
  }
  @keyframes upDown {
    to {
      transform: translatey(50px);
    }
  }
  @keyframes translate {
    to {
      transform: translatex(450%);
    }
  }
}

.app-container {
  max-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.header-component {
  // background-color: $darkblue;
  color: $white;
  position: fixed;
  width: 100%;
  z-index: 70;
  .header-row {
    background-color: $logo-color;
    min-height: 43px;
  }
  .counter-box.ng-enter {
    transition: 0.8s linear all;
    opacity: 0;
  }

  .counter-box-init .counter-box.ng-enter {
    opacity: 1;
  }

  .counter-box.ng-enter.ng-enter-active {
    opacity: 1;
  }

  .counter-box.ng-leave {
    transition: 0.3s linear all;
    opacity: 1;
  }
  .counter-box.ng-leave.ng-leave-active {
    opacity: 0;
  }

  .counter-row {
    background-color: $blue;
    border-radius: 0 0 0 4px;
  }
  .atlas-menu {
    margin: 0 15px;
    font-size: 26px;
  }
  .sys-btn {
    min-width: fit-content;
    margin-right: 0;
    padding: 0;
    color: #aeadad;
  }
  .logo {
    margin: 3px 16px 0 0;
    h1,
    p {
      margin: 0;
      text-align: center;
    }
    h1 {
      font-size: 1.6em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif; //background-color: $black;
      padding: 7px 0;
      float: left;
      margin-left: 2px;
    }
    a {
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      text-decoration: none !important ;
    }
    a:hover {
      text-decoration: none !important ;
    }
    .home-altas {
      font-size: 26px;
    }
    .sami-icon {
      margin-left: 5px;
      margin-top: 2px;
      font-weight: 500;
    }

    .site-ind-select {
      margin: 0;
      text-align: center;
      md-select .md-select-value {
        text-transform: uppercase;
        font-weight: 500;
        border-bottom-style: none;
        padding: 0;
      }
      .md-select-icon {
        display: none;
      }
    }
  }
  .middle-column {
    padding-right: 16px;
  }
  .greeting {
    float: left;
    font-weight: 600;
    padding: 0;
    h5 {
      margin: 14px 0;
    }
    max-height: 43px;
  }
  .bar {
    // height: 10px;
    // font-size: x-small;
    // margin: 0;
    // padding: 0;
    // background-color: orange;
  }
  .bar-button {
    background-color: #01d8b7;
    color: #eef0f3;
    float: right;
    height: 5px;
    font-size: 9px;
    margin-top: 10px;
    padding: 0;
    /* width: 5%; */
    line-height: 6px;
    min-height: 22px;
    /* text-align: center; */
    text-shadow: 0.5px 0.5px 0.5px #000;
    /* position: relative; */
    margin-left: 5px;
  }
  .notifications {
    padding: 0 10px 0 0;
    .header-chat {
      vertical-align: middle;
      margin-right: 5px;
    }
    img {
      cursor: pointer;
      // margin: auto 3px;
      max-width: 32px;
      max-height: 32px;
      padding: 6px;
      margin-top: -4px;
    }
    .counter-img {
      padding: 4px;
    }
    img:hover {
      background-color: $lightblue;
    }
  }
  .info-box {
    width: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background-color: $lightblue;
    overflow: hidden;
    padding-top: 9px;
    margin: 0;
    display: inline-block;
    transition: width 0.3s;
  }
  .counter-container {
    height: 43px;
    max-height: 43px;
    right: 0;
    float: right;
    width: auto;
    &:hover {
      border: 1px solid $lightblue;
      .info-box {
        height: auto;
        width: 120px;
      }
    }
    .data-box {
      float: right;
      display: inline-block;
      margin: 8px 5px;
      span.counter {
        float: left;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        height: auto;
      }
    }
    max-height: 43px;
  }
}

// **start reports style **
.metric-box-container {
  // max-width: 80vw;
  .metric-box {
    // margin: 0 20px;
    // border: 0.5px solid $blue;
    .value {
      padding: 0 5%;
      font-size: 40px;
      text-align: center;
      width: 10vw;
    }
    .title {
      text-align: center;
      color: $white;
      background-color: $lightblue;
      font-weight: bold;
      padding: 0 50%;
      width: 10vw;
      font-size: 23px;
    }
  }
}

.demand-container {
  .demand-metrics {
    &.left {
      float: left;
      width: 50%;
    }
    &.right {
      width: 50%;
      float: right;
    }
    ul {
      // display: block;
      padding: 0;
      margin: 0;
    }
    li {
      &.title {
        background-color: $blue;
        color: $white;
        font-weight: bolder;
        float: left;
      }
      background-color: $lightblue;
      color: $white;
      list-style: none;
      display: block;
      width: 25%;
      text-align: center;
      float: left;
    }
  }
}

// ** end reports style **
// .nav-component {
//     background-color: $secondaryblue;
//     color: $white;
//     width: 15vw;
//     height: 95vh;
//     padding-top: 0;
//     float: left;
//     display: inline-block;
//     div {
//         width: 100%;
//         padding-top: 15px;
//     }
//     md-icon {
//         margin: 0 5px;
//         color: $white;
//     }
//     md-list-item {
//         padding: 0;
//         div {
//             padding: 0;
//             width: 15vw;
//             height: 48px;
//         }
//     }
//     .navlist {
//         a:hover,
//         a:focus div {
//             text-decoration: none;
//         }
//         a:focus md-icon,
//         a:hover md-icon,
//         a:focus md-icon {
//             color: $secondaryblue;
//         }
//         a:hover div,
//         a:focus div {
//             background-color: $white !important;
//             cursor: pointer;
//         }
//         a.active .nav-title,
//         a:hover .nav-title,
//         a.active .nav-title {
//             font-size: 1.2em;
//             font-weight: 700;
//             color: $secondaryblue !important;
//         }
//         .sub-nav {
//             padding: 0;
//             padding-top: 8px;
//             padding-left: 40px;
//             width: 15vw;
//         }
//     }
//     .logo {
//         padding: 0;
//         h1,
//         p {
//             margin: 0;
//             text-align: center;
//         }
//         h1 {
//             font-size: 1.5em;
//             font-weight: 700;
//             text-transform: uppercase;
//             font-family: 'Montserrat', sans-serif;
//             background-color: $darkblue;
//             padding: 10px 0;
//         }
//         p,
//         md-input-container {
//             padding: 0;
//             background-color: $lightblue;
//             color: $white;
//             text-align: center;
//             margin: 0;
//         }
//         md-select[disabled] .md-select-value {
//             color: $white;
//             background-image: none;
//         }
//         .md-select-value .md-select-icon {
//             display: none;
//         }
//     }
//     .nav-footer {
//         background-color: $lightblue;
//         position: absolute;
//         bottom: 5vh;
//         padding-top: 0;
//         width: 15vw;
//         p {
//             margin: 0;
//         }
//     }
//     .md-menu-bar {
//         display: inline-flex;
//     }
//     .nav-title {
//         text-transform: uppercase;
//         font-size: 0.95em;
//         margin: auto 0;
//     }
//     i {
//         font-size: 1.6em;
//         margin-right: 10px;
//     }
//     br {
//         display: none;
//     }
//     md-divider {
//         background-color: $white;
//     }
//     a {
//         color: $white;
//         img {
//             width: 32px;
//             height: 32px;
//             margin-left: 10px;
//             margin-right: 10px;
//         }
//     }
// }

.view-container {
  overflow: hidden;
  height: 95vh;
  background-color: $bg-grey;
  //padding-left: 30px;
  max-width: 100%;
  margin: 0;
  padding-top: 0;
  float: left;
  overflow-y: auto;
  .content-container {
    max-height: 80vh;
    padding-top: 50px;
  }
}

.footer-component {
  background-color: $logo-color;
  color: $white;
  width: 100vw;
  height: 5vh;
  text-align: center;
  z-index: 75;
  .logo {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    margin-left: 16px;
  }
  .plug {
    font-size: 12px;
    font-weight: 500;
  }
  span {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    letter-spacing: 0.7px;
  }
  .plug-logo {
    margin-right: 8px;
    font-weight: 500;
  }
}

@import url("//fonts.googleapis.com/css?family=Arimo");
.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-car .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-car
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  -moz-border-radius: 0.34em;
  -webkit-border-radius: 0.34em;
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: $blue;
  color: $white;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  -moz-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, $secondaryblue),
    color-stop(40%, $secondaryblue),
    color-stop(60%, $lightblue),
    color-stop(80%, $secondaryblue),
    color-stop(100%, $secondaryblue)
  );
  background-image: -moz-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    $blue 0%,
    $blue 40%,
    $lightblue 60%,
    $blue 80%,
    $blue 100%
  );
  background-image: linear-gradient(
    to bottom,
    $lightblue 0%,
    $lightblue 40%,
    $blue 10%,
    $lightblue 80%,
    $lightblue 100%
  );
  padding: 0 0.15em;
}

.odometer.odometer-auto-theme .odometer-digit:first-child,
.odometer.odometer-theme-car .odometer-digit:first-child {
  -moz-border-radius: 0.2em 0 0 0.2em;
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em 0 0 0.2em;
}

// .odometer.odometer-auto-theme .odometer-digit:last-child,
// .odometer.odometer-theme-car .odometer-digit:last-child {
//     -moz-border-radius: 0 0.2em 0.2em 0;
//     -webkit-border-radius: 0;
//     border-radius: 0 0.2em 0.2em 0;
//     background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjZWVlMGQzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiNiYmFhOWEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VlZTBkMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
//     background-size: 100%;
//     background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $white), color-stop(40%, $white), color-stop(60%, #bbaa9a), color-stop(80%, $white), color-stop(100%, $white));
//     background-image: -moz-linear-gradient(top, $white 0%, $white 40%, #bbaa9a 60%, $white 80%, $white 100%);
//     background-image: -webkit-linear-gradient(top, $white 0%, $white 40%, #bbaa9a 60%, $white 80%, $white 100%);
//     background-image: linear-gradient(to bottom, $white 0%, $white 40%, #bbaa9a 60%, $white 80%, $white 100%);
//     background-color: $white;
//     color: #000;
// }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.area {
  animation: blur 0.3s ease-out 1;
  // text-shadow: 0px 0px 5px #fff, 0px 0px 7px #fff;
}

@keyframes blur {
  from {
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 25px #fff,
      0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff,
      0px 0px 25px #fff, 0px 0px 50px #fff, 0px 0px 50px #fff,
      0px 0px 50px #7b96b8, 0px 0px 150px #7b96b8, 0px 10px 100px #7b96b8,
      0px 10px 100px #7b96b8, 0px 10px 100px #7b96b8, 0px 10px 100px #7b96b8,
      0px -10px 100px #7b96b8, 0px -10px 100px #7b96b8;
  }
}

.leaflet-top,
.leaflet-bottom {
  z-index: 75;
}

.md-select-menu-container {
  z-index: 1001;
}

.sys-eff img {
  width: 3vw;
  height: 3vw;
  margin-left: 5px;
  max-height: 40px;
  max-width: 40px;
}

.sys-eff-age img {
  min-width: 55px;
  min-height: 40px;
}

.legend {
  text-transform: capitalize;
}

.site-select {
  text-transform: uppercase;
  font-weight: 700;
}

.site-option {
  text-transform: capitalize;
}

.Blink {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0.2;
  }
}

.text-danger {
  color: red !important;
  font-weight: 500;
}

.text-normal {
  color: #1aae1a !important;
  font-weight: 500;
}

.onpeak-icon {
  width: 100%;
  position: relative;
  background-color: $darkblue;
  color: $white;
  display: block;
  text-align: right;
  padding-right: 3%;
  font-weight: 500;
  padding: 0.5% 2% 2% 2%;
}

.xtick {
  font-size: 10px !important;
}

.notification-list {
  position: absolute;
  top: 40px;
  right: 0;
  width: 300px;
  z-index: 1001;
  background-color: $lightblue;
  color: $white;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
}

.notification-time {
  display: inline-block;
  margin-left: 11vw;
  font-size: smaller;
}

.notification-val {
  margin-left: 3px;
  font-size: 12px;
  position: absolute;
  left: 10px;
  font-weight: 600;
}

.circle {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  font-size: 16px;
  text-transform: capitalize;
  color: $white;
  line-height: 43px;
  text-align: center;
  background: #000;
  margin: 8px;
  display: inline-block;
}

.list-item {
  border-bottom: 2px outset $secondaryblue;
}

.notification-message {
  display: inline-block;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 12px;
}

.md-history {
  background-color: $blue;
  &:hover {
    background-color: $darkblue !important;
    color: $white;
  }
}
.notification-container {
  padding-top: 50px;
  md-content {
    // background-color:$white;
  }
  .notification-header {
    span {
      font-size: 1vw;
      font-weight: 500;
    }
    md-icon {
      color: $blue;
    }
    .get-date {
      // position: absolute;
      // left: 15vw;
      // top: 10px;
    }
    .filter-alert {
      // display: inline-block;
      // position: absolute;
      // left: 15vw;
      // top: 0;
    }
  }
}

.md-close {
  float: right;
  background-color: $lightred;
  &:hover {
    background-color: $red !important;
    color: $white;
  }
}

.tg-list {
  display: inline-block;
  font-size: 16px;
  position: absolute;
  top: 6px;
  left: -35px;
}

.tg-list-item {
  list-style: none;
}

.tgl {
  display: none;
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }
  + .tgl-btn {
    outline: 0;
    display: block;
    width: 10.5em;
    height: 30px;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    span {
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: bold;
      color: $white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 16%;
      height: 100%;
    }
    &:after {
      left: 0;
    }
    &:before {
      display: none;
    }
  }
  &:checked + .tgl-btn:after {
    left: 85%;
  }
}

.tgl-light {
  padding: 0;
  + .tgl-btn {
    background: $red;
    overflow: hidden;
    border-radius: 0.5em;
    padding: 2px;
    transition: all 0.4s ease;
    span {
      margin-right: 20px;
      margin-top: 4px;
      width: 59%;
      text-align: left;
      font-size: 11px;
      letter-spacing: 0px;
      float: right;
      vertical-align: middle;
      line-height: 9px;
    }
    &:after {
      top: 0;
      left: 0;
      width: 15%;
      border-radius: 6px;
      background: #fff;
      transition: all 0.2s ease;
    }
  }
  &:checked + .tgl-btn {
    background: #099f09;
    span {
      margin-left: 5px;
      margin-top: 7px;
      display: block;
      text-align: left;
    }
  }
}

.icon-set {
  color: white;
  position: absolute;
  top: 10px;
  left: 10%;
}

.icon-report {
  color: white;
  position: absolute;
  top: 10px;
  left: 12%;
}

.report-md {
  width: 80vmin;
  height: 80vmin;
  .clearfix {
    height: 100%;
  }
  object {
    width: 100%;
    height: 100% !important;
  }
}
.js-plotly-plot .plotly .modebar {
  z-index: 2 !important;
}
.well-info {
  float: right;
  margin: 7px 10px 0 0;
}

.status-msg {
  .status-icon {
    margin: 0 0 0 10px;
  }
  .more-info {
    .info-btn {
      background-color: white;
      margin-right: 10px;
      color: red;
      min-height: 26px;
      max-height: 30px;
      line-height: inherit;
    }
  }
}

// md-select:not([disabled]):focus .md-select-value {
//     color:$white;
// }

md-select .md-select-icon {
  color: $white;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

.show-button {
  opacity: 0;
  transition: all 1s linear, opacity 1ms;
  transform: rotate(0deg);
}