@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700italic,900,900italic,300italic,300,100italic,100,700);
.fullscreen-chart iframe,
#myHistoryDialog-db-1 iframe {
    // position: absolute;
    // bottom: 0; 
    // left: 0;
    width: 100%;
    height: 60vh;
}

.fullscreen-chart iframe,
#myHistoryDialog-db-2 iframe {
    // position: absolute;
    // bottom: 0; 
    // left: 0;
    width: 100%;
    height: 60vh;
}

md-card iframe {
    height: 30vh;
}

.loading-gears {
    &.text {
        text-align: center;
        margin-left: 30px;
        display: block;
    }
    &.img {
        width: 4vw;
        height: auto;
        position: relative;
        left: 45%;
        margin-top: 2vh;
        margin-bottom: 0.2vh;
    }
}

md-tabs-content-wrapper {
    min-height: 60vh;
}

md-tab-canvas md-tab-item#md-active {
    background-color: #499fd4;
    color: white;
    span {
        text-shadow: 2px 2px 5px Black;
    }
}

md-tab-canvas md-tab-item#md-active,
md-tabs md-ink-bar {
    color: #499FD4;
    background-color: #499FD4;
}

.frame,
.frame-2,
.frame-3 {
    .chart-views {
        // padding-left: 48vw;
    }
}

.fullscreen-chart {
    .chart-views {
        // padding-left: 48vw;
    }
    .md-button,
    .chart-views .md-button {
        background-color: #499ED4;
        width: 20%;
        color: white;
    }
    .md-button span {
        text-shadow: 2px 2px 2px black;
    }
}

.myHistoryDialog>md-dialog>md-tooolbar,
.md-dialog-container .card-heading.dashboard {
    max-height: 2vh;
}

.myHistoryDialog>md-dialog>md-tooolbar,
.md-dialog-container .card-heading {
    // background-color: $black;
    max-height: 7vh;
    // text-transform: capitalize;
}

md-radio-group {
    text-transform: capitalize;
    padding: 5px 0;
}


.modebar-group {
    svg{
        display: unset;
        width:unset;
        height:unset;
    }
}

.md-dialog-container{
    z-index: 79 !important;
}

// .atlas-note-btn{
//     position: absolute;
//     z-index: 10000;
// }
// injector
@import 'client/app/components/app-utils/variable.scss';
@import 'client/app/components/chat/chat.scss';
@import 'client/app/components/dashboard/dashboard.scss';
@import 'client/app/components/decode/decode.scss';
@import 'client/app/components/digital_note/digital_note.scss';
@import 'client/app/components/layout/header.scss';
@import 'client/app/components/layout/layout.scss';
@import 'client/app/components/login/login.scss';
@import 'client/app/components/map/map.scss';
@import 'client/app/components/notification_history/notification_history.scss';
@import 'client/app/components/pump_health_analysis/pha.scss';
@import 'client/app/components/realtime_operations/rto.scss';
@import 'client/app/components/reports/reports.scss';
@import 'client/app/components/settings/setting.scss';
@import 'client/app/components/synchronized_charts/sc.scss';
@import 'client/app/components/system_statistics/ss.scss';
@import 'client/app/components/well_health_analysis/wha.scss';
@import 'client/app/components/card/control_card/cc.scss';
// endinjector

