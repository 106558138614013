$primary: #4D5322;
$secondary: #ff9800;
$tertiary: #00b4a1;
$quaternary: #5923e3;
// $quinary: #;
// $senary: #;
// $septenary: #;
// $octonary: #;
// $nonary: #;
// $denary: #;
$white: #EEF0F3;
$dark-black: #000;
$black: #292B2F;
// $blue: #0982CB;
$blue-tint: rgba(3, 48, 75, 0.03);
// $darkblue: #03304B;
$orange: #CB7909;
$orange-tint:rgba(203, 121, 9, 0.04);
$lightblue: #499FD4;
$secondaryblack: #393C42;
$secondaryblue: #2C6487;
$red: #D41F22;
$lightred:#CE5656;
$yellow: #F0D705;
$violet: #7777ff;
$green: #01D8B7;
$darkgreen: #01584A;
$grey: #cac8c8;
$light-grey: #e9e9e9;
$gaugeblue: rgb(31, 119, 180);
$gaugegreen: rgb(44, 160, 44);
$gaugedarkgreen: #01584A;
$gaugeorange: rgb(255, 127, 14);
$gaugered: rgb(214, 39, 40);
$gaugeyellow: $yellow;
$gaugepurple: rgb(148, 103, 189);
$gaugeblue: steelblue;
$break-sm-computer: 1024px;
$break-lg-computer: 1280px;
$break-xl-computer: 1440px;


$logo-color:#4964ae;
$bg-grey:#758185;
$blue:#313941;
$darkblue: #313941;

$logo-color1:#6990FA;
$logo-color2:#2E3F6E;
$logo-color3:#3E5594;
$logo-color4:#4E6BBB;

$secondaryblue: #375cc5;