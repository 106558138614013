.note-container {
  padding-top: 50px;
  md-icon {
    margin: 0 10px;
  }
  .pagination-table {
    color: white !important;
    background-color: $logo-color2 !important;
    font-size: 12px;
    height: 40px;
    md-icon {
      margin: 0;
      color: white;
    }
    md-select.md-table-select {
      margin: 20px 0 26px 0;
    }
    .md-button[disabled] {
      md-icon {
        color: red !important;
      }
    }
  }
  md-select.md-table-select > .md-select-value > span > .md-text {
    color: $white;
    font-weight: 500;
  }
  table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
    background-color: #e6e5e5 !important;
  }
  table.md-table tbody.md-body > tr.md-row > td {
    font-weight: 500;
    // text-transform: capitalize;
  }
  table.md-table th.md-column {
    color: $black;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  table.md-table thead.md-head > tr.md-row {
    height: 26px;
  }
  .no-results {
    padding: 0 16px;
  }
  .analysis-chart iframe {
    height: 60vh;
  }
  .note-tb-body {
    md-icon {
      color: $dark-black;
    }
    .comment-count {
      vertical-align: text-top;
      font-size: 12px;
      background-color: #f44336;
      padding: 4px;
      border-radius: 50%;
      color: white;
      font-weight: 500;
      margin-left: -10px;
      margin-bottom: -20px;
    }
  }
  .notification-header {
    background-color: $logo-color2;
    color: white;
    height: 40px;
    .expand-icon {
      margin-left: 10px;
    }
    .notification-title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.2px;
      margin-left: 5px;
    }
  }
  .md-table-pagination > .buttons {
    margin-right: 40px;
  }
  .catalog-icon {
    margin-right: 10px;
  }
  md-content {
    background: none;
  }
}

.edit-note-dialog {
  width: 600px !important;
  height: fit-content !important;
  md-dialog-content {
    width: 600px !important;
    height: 60vh !important;
  }
  form {
    overflow: unset;
  }
  .edit-note-dialog-headar {
    // min-height: 50px !important;
    background-color: $darkblue;
    color: white;
    md-icon {
      margin: 0 8px;
    }
    .note-action-headar {
      min-height: 50px !important;
    }
    .edit-note-header-text {
      font-size: 17px;
      padding-left: 10px;
      font-weight: 500;
    }
    .note-search-headar {
      background-color: white;
      min-height: 50px !important;
      border: 2px #03a9f4 solid;
      border-radius: 2px;
      md-icon {
        color: black;
        margin: auto;
      }
      .md-datepicker-input {
        min-width: 75px;
        // color: white;
      }
      .end-date-input {
        // margin:0;
      }
      md-input-container .md-errors-spacer {
        min-height: 18px !important;
      }
      .search-input {
      }
      .apply-icon {
        // margin: 0;
        width: 23px;
        height: 23px;
        min-height: 23px;
        padding: 0;
        background-color: #03a9f4 !important;
        md-icon {
          color: white;
          font-size: 18px;
          line-height: 1.3;
          margin: auto;
        }
      }
      .reset-icon {
        // margin: 0;
        width: 23px;
        height: 23px;
        min-height: 23px;
        padding: 0;
        background-color: #f44336 !important;
        md-icon {
          color: white;
          font-size: 18px;
          line-height: 1.3;
          margin: auto;
        }
      }
    }
  }
  .edit-note-text-area {
    background-color: #f7f6f3;
    border: 2px #2196f3 solid !important;
    border-radius: 4px;
    padding-left: 10px;
  }
  .edit-comment-text-area {
    background-color: #f7f6f3;
    border: 1px #ffc107 solid !important;
    border-radius: 4px;
    padding-left: 10px;
  }
  .comment-note {
    // background-color: #3f51b5;
    color: black;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin: 8px;
    border-radius: 4px;
    padding: 4px;
    &:hover {
      background-color: #e4e2e2;
    }
  }
  md-input-container {
    margin-bottom: 0 !important;
  }
  .edit-btn {
    background-color: #ff5722;
    color: white;
    min-height: unset;
    // text-transform: capitalize;
    min-width: unset;
    line-height: unset;
    md-icon {
      margin: auto;
    }
  }
  .update-button {
    background-color: #2196f3;
    text-transform: capitalize;
    min-height: unset;
    line-height: unset;
    color: white;
    md-icon {
      margin: auto;
    }
  }
  .comment-update-button {
    background-color: #FF9800;;
    text-transform: capitalize;
    min-height: unset;
    line-height: unset;
    color: white;
    md-icon {
      margin: auto;
    }
  }
  .cancel-button {
    background-color: #9e9e9e;
    min-height: unset;
    line-height: unset;
    text-transform: capitalize;
    md-icon {
      margin: auto;
    }
  }
  .comm-title {
    font-size: 18px;
    font-weight: 500;
    color: #673ab7;
  }
  .note-name-bar {
    padding: 10px 10px 0 10px;
    .note-name {
      color: #f44336;
      font-weight: 500;
    }
    .note-time {
      color: #9e9e9e;
      font-weight: 500;
    }
  }

  .comment-section {
    .comment-note {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.1px;
      margin: 8px;
      border-radius: 4px;
      padding: 4px;
    }
    .edit-comment {
      padding-left: 10px;
      color: #9e9e9e;
    }
    .you {
      // background-color: #2196f3;
    }
    .other {
      // background-color: #009688;
    }
    .you-name {
      color: #2196f3;
    }
    .other-name {
      color: #009688;
    }
    .comment-name-bar {
      padding: 0 10px;
      .comment-name {
        // color: #009688;
        font-weight: 900;
        text-transform: capitalize;
        .note-date {
          font-weight: 400;
          font-size: 12px;
        }
      }
      .comment-time {
        color: #9e9e9e;
        font-weight: 500;
      }
      .edit-time {
        text-transform: lowercase;
        color: #9e9e9e;
        font-weight: 500;
        font-size:10px;
      }
    }
    .menu-ops {
      md-icon {
        margin: auto;
        color: black;
      }
    }

    .image-section-comment {
      .note-file-name {
        text-decoration: underline;
        padding-left: 10px;
      }
      md-icon {
        margin: auto;
        margin: 0 10px;
      }
    }
    .image-section-note {
      .note-file-name {
        text-decoration: underline;
        // padding-left:10px;
      }
      md-icon {
        margin: auto;
        margin: 0 10px;
      }
    }
    .comments-section {
      padding-left: 30px;
      md-icon {
        margin: auto;
        color: black;
      }
      .comment-user-section {
        color: #ff5722;
      }
      .comment-list-section {
        padding: 0 20px;
        .comment-user-name {
          font-size: 12px;
          font-weight: 500;
        }
        .comment-user-date {
          font-size: 10px;
          font-weight: 400;
        }
        .user-comment-text {
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
        }
      }
    }
  }
  .type-comment {
    md-icon {
      margin: auto;
      color: #607d8b;
    }
    // height:200px;
    order: 2;
    border-top: 1px #9e9e9e solid;
    md-input-container {
      width: 100% !important;
    }
    .note-file-upload {
      cursor: pointer;
      padding-bottom: 30px;
    }

    .send-icon {
      // margin: 0;
      width: 32px;
      height: 32px;
      min-height: 32px;
      padding: 0;
      background-color: #2196f3;
      md-icon {
        color: white;
        font-size: 20px;
        line-height: 1.3;
        margin: auto;
      }
    }
    .add-btn {
      padding-bottom: 20px;
    }
    label {
      margin-bottom: 1px;
      margin-left: 6px;
    }
  }

  .type-comment-note {
    padding: 0 20px;
    md-icon {
      margin: auto;
      color: #607d8b;
    }
    // height:200px;
    // order: 2;
    md-input-container {
      width: 100% !important;
    }
    label {
      margin-bottom: 1px;
      margin-left: 6px;
    }
    .note-file-upload {
      cursor: pointer;
      padding-bottom: 30px;
    }
    .add-btn {
      padding-bottom: 20px;
    }
    .comment-icon {
      // margin: 0;
      width: 32px;
      height: 32px;
      min-height: 32px;
      padding: 0;
      background-color: #ff9800;
      md-icon {
        color: white;
        font-size: 20px;
        line-height: 1.3;
        margin: auto;
      }
    }
  }

  .note-error {
    color: black;
    md-icon {
      color: red;
    }
  }
}

.options-menu {
  md-menu-item {
    height: unset !important;
    min-height: unset !important;
  }
}

.view-img-header {
  .view-file-name {
    font-weight: 500;
    font-size: 15px;
    padding: 4px;
  }
  .view-user-name-section {
    padding: 4px;
    .user-name {
      font-weight: 500;
      font-size: 14px;
    }
    .file-upload-date {
      font-weight: 400;
      font-size: 13px;
    }
  }
  .view-header-icons {
    .image-download {
      color: black;
      margin: 0 20px;
    }
    .close-view-window {
      color: black;
      margin: 0 10px;
    }
    .view-spacer {
      width: 1px;
      height: 32px;
      background: grey;
    }
  }
}

.note-panel-container {
  background-color: white;
  top: 50%;
  bottom: 110px;
  right: 10px;
  left: 72%;
  position: absolute;
  border: 2px $logo-color solid;
  border-radius: 10px;
  height: 300px;
  width: 380px;

  // top: 0px;
  // bottom: 0px;
  // left: 0px;
  // right: 0px;
  overflow: hidden;
  .user-note-form {
    md-input-container {
      margin-bottom: 0;
      .user-note-box {
        height: 200px !important;
      }
    }
  }
}

.atlas-note-btn {
  position: absolute;
  // z-index: 80;
  right: 0;
  bottom: 45px;
  md-icon {
    margin: auto;
  }
  .note-count{
    // position: absolute;
    // display: inline-block;
    top: -1px;
    right: 2px;
    color: #fff;
    background: #3f51b5;
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform .2s ease-in-out;
    transform: scale(.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

}

.user-note-form {
  .file-upload-ip {
      padding:10px;
      label{
          margin:0;
      }
    md-input-container {
      margin-bottom: 0;
      .md-input {
        border: 1px $logo-color solid !important;
        border-radius: 4px;
      }

      .user-note-box {
        //   height: 200px !important;
      }
    }

    .md-input-focused {
      .md-input {
        box-shadow: 0 0 0 1px #9bd3fe, 0 0 0 1px rgba(29, 155, 209, 0.3);
      }
    }
  }
  .size-check{
    color:red;
    font-weight:500;
  }
  .upload-file-name{
    font-weight:500;
  }

  .upload-progress-sec{
    .upload-progress{
      margin:0;
      height: 24px;
      background-color: transparent;
      border-radius: 8px;
    }
    .upload-progress-bar{
      font-size: 12px;
      line-height: 24px;
      color: #fff;
      text-align: center;
      background-color:#4CAF50;
    }
  }

  .upload-file-header {
    .upload-file-text {
      font-size: 22px;
      font-weight: 500;
      padding: 6px;
    }
    .close-upload-dialog {
      md-icon {
        margin: auto;
        color:black;
      }
      padding: 6px;
    }
  }
  .preview-img-block {
    margin: 10px;
    border: 2px #03A9F4 solid;
    .preview-img {
      width: 480px;
    }
  }
}

.user-note-form-img{
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin:50px;
  img{
    // width:76vw;
    height:100vh;
    transform: translate3d(0px, 0px, 0px) rotate(0deg) scale(0.585723, 0.585723);
    // cursor: zoom-in;
    transition: transform 200ms ease 0s;
  }
}
