.stats-table {
    font-weight:500;
    table.md-table th.md-column,
    table.md-table td.md-cell {
        text-align: center !important;
        padding: 0 !important;
        font-size: 14px !important;
    }
    table.md-table tbody.md-body>tr.md-row {
        height: 30px !important;
        text-align: center !important;
    }
    table.md-table thead.md-head>tr.md-row {
        height: 30px !important;
    }

    // .table-striped>tbody>tr:nth-child(odd)>td{
    //     background-color: #d72828c7;
    //     color:white !important;
    // }
    // .table-striped>tbody>tr:nth-child(even)>td{
    //     background-color: #4682b4;
    //     color:white !important;
    // }

}