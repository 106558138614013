.ss-container {
    width: 100%;
    padding-top: 50px;
    
    md-radio-group {
        margin: 5px 0 0 15px;
    }
    md-card {
        padding: 0 !important;
        md-input-container.md-block {
            margin: 0;
            md-select {
                width: 6vw
            }
        }
        &.table-container {
            padding: 0;
            overflow: hidden;
            td.table-cell-heading {
                font-weight: bolder;
                span {
                    text-transform: none;
                }
            }
        }
        a.md-button.md-primary.md-raised,
        a.md-button.md-primary.md-fab,
        .md-button.md-primary.md-raised,
        .md-button.md-primary.md-fab {
            background-color: #D17E3E;
        }
        a.md-button.md-primary.md-raised:not([disabled]):hover,
        a.md-button.md-primary.md-fab:not([disabled]):hover,
        .md-button.md-primary.md-raised:not([disabled]):hover,
        .md-button.md-primary.md-fab:not([disabled]):hover {
            background-color: #E55921;
        }
        md-card-content.content-table {
            padding: 0;
            width: 100%;
        }
        .table-heading,
        tbody td {
            text-transform: capitalize;
        }
        md-toolbar {
            text-align: center;
            text-transform: capitalize;
        }
    }
    // md-card:nth-child(1) .card-heading {
    //     background-color: #878628;
    // }
    // md-card:nth-child(2) .card-heading {
    //     background-color: #DB6161;
    // }
    // md-card:nth-child(3) .card-heading {
    //     background-color: #499ED4;
    // }
    // md-card:nth-child(4) .card-heading {
    //     background-color: #A19F27;
    // }
    // md-card:nth-child(5) .card-heading {
    //     background-color: #216087;
    // }
}

.frame {}

.frame2 {}

.frame3 {
    md-radio-group {
        md-radio-button {
            checked .md-ink-ripple {
                color: rgb(135, 134, 40);
            }
            .md-off {
                border-color: rgb(135, 134, 40);
            }
        }
    }
}

.frame4 {}

.frame5 {}