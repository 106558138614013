.realtime-container {
    overflow-y: scroll;
    width: 100%;
    md-card {
        overflow: hidden;
        max-height: 50vh;
        height: 38.5vh;
        md-card-actions {
            button {
                padding-top: 19px;
            }
        }
        md-toolbar {
            // background-color: $blue;
            // color: $white;
            text-align: center;
        }
    }
}

.pump-name {
    text-transform: uppercase;
}

.md-select-menu-container.md-active md-select-menu {
    text-transform: uppercase;
}

.onpeak-expand {
    width: 100%;
    background-color: $lightblue;
    text-transform: uppercase;
    font-weight: 12px;
    color: white;
    display: block;
    text-align: right;
    padding-right: 2%;
}