// .md-dialog-container {
//     z-index: -70;
// }

.bg-S,.bg-ALL {
    background-color: #3c3c3c !important;
    color: $white;
    &.off {
        background-color: grey !important;
    }
    &.on {
        background-color:  #3c3c3c !important;
    }
    &.bg-S:hover {
        color: $black;
        // font-size: 20px;
        // font-weight:500;
    }
}
.bg-A {
    background-color: $red !important;
    color: $white;
    &.off {
        background-color: grey !important;
    }
    &.on {
        background-color:  $red !important;
    }
    &.bg-A:hover {
        color: $black;
        // font-size: 20px;
        // font-weight:500;
    }
}
.bg-M {
    background-color: #00B04F !important;
    color: $white;
    &.off {
        background-color: grey !important;
    }
    &.on {
        background-color:  #00B04F !important;
    }
    &.bg-M:hover {
        color: $black;
        // font-size: 20px;
        // font-weight:500;
    }
}
.bg-I {
    background-color: $blue !important;
    color: $white;
    &.off {
        background-color: grey !important;
    }
    &.on {
        background-color:  $blue !important;
    }
    &.bg-I:hover {
        color: $black;
        // font-size: 20px;
        // font-weight:500;
    }
}

.md-sidenav-left{
    z-index: 1001;
    overflow: initial;
    width: fit-content;
    md-select[disabled] .md-select-value{
        color:white;
    }
    .md-select-icon{
        display:none;
    }
    md-select[disabled] .md-select-value {
        border-bottom-color: rgba(0,0,0,0.12);
        text-align:center;
        // background-image: none;
    }
}

.menu-content{
        background-color: $logo-color;
        color: $white;
        width: auto;
        height: 100vh;
        padding-top: 0;
        
        display: inline-block;
        .greeting{
            text-transform: capitalize;
        }
        div {
            width: 100%;
            padding: 5px 0;
        }
        md-icon {
            margin: 0 5px;
            color: $white;
        }
        md-list-item {
            padding: 0;
            div {
                padding: 0;
                width: 15vw;
                height: 48px;
            }
        }
        .navlist {
            a:hover,
            a:focus div {
                text-decoration: none;
            }
            a:focus md-icon,
            a:hover md-icon,
            a:focus md-icon {
                color: $secondaryblue;
            }
            a:hover div,
            a:focus div {
                background-color: $white !important;
                cursor: pointer;
            }
            a.active .nav-title,
            a:hover .nav-title,
            a.active .nav-title {
                // font-size: 1.2em;
                // font-weight: 700;
                color: $secondaryblue !important;
            }
            .sub-nav {
                padding: 5px 0;
                padding-left: 40px;
                // width: 15vw;
            }
        }
        .logo {
            padding: 0;
            h1,
            p {
                margin: 0;
                text-align: center;
            }
            h1 {
                font-size: 1.5em;
                font-weight: 700;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                background-color: $darkblue;
                padding: 10px 0;
            }
            p,
            md-input-container {
                padding: 0;
                background-color: $lightblue;
                color: $white;
                text-align: center;
                margin: 0;
            }
            md-select[disabled] .md-select-value {
                color: $white;
                background-image: none;
            }
            .md-select-value .md-select-icon {
                display: none;
            }
        }
        .nav-footer {
            img{
                width: 100%;
                height: auto;
                margin-top: 5vh !important;
            }
        }
        .md-menu-bar {
            display: inline-flex;
        }
        .nav-title {
            text-transform: uppercase;
            font-size: 0.95em;
            margin: auto 0;
            padding-right:10px;
            font-weight:500;
        }
        .note-button{
            position: absolute;
            bottom: 0;
            left:5.5vh;
            .note-btn{
                background-image: linear-gradient(to bottom, #5e6168, #575a60, #505357, #494c4f, #434547);
                &:hover{
                    background-image: linear-gradient(to right, #d45300, #da5700, #e05a00, #e65e00, #ec6200);
                }
            }
        }
        i {
            font-size: 1.6em;
            margin-right: 10px;
        }
        br {
            display: none;
        }
        md-divider {
            background-color: $white;
        }
        a {
            color: $white;
            img {
                width: 32px;
                height: 32px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

}

.system-select{
    color:white !important;
    // background-color: $lightblue;
}


.note-list{

    // .md-2-line{
    //     height:50px;
    //     min-height:50px !important;
    // }
    span{
        font-size: 16px;
        // text-transform: capitalize;
        font-weight:500;
    }
}

 
.ant-wrapper {
    background-color: $black;
    // min-height: 5vh;
    // min-height: 5vh;
    width: 100%;
    overflow: hidden;  
    // margin-top: 5vh;
        max-height: 3.5vmin !important;
    md-menu button{
        border-color: none !important;
    }
    .spacer {
        height: 5vh;
        width: 100vw;
    }
    .ant-container{
        margin-top: -5.8px;
    }
    .ant-text-wrapper {
        position: absolute;
        left: 8%;
        z-index: 4 !important;
        padding: 0 15%;
        margin-right: 100px; 
    }
    .ant-controls {
        // min-width: 3vw;
        // // position: static;
        // // left: 0;
         z-index: 10;
         height: 4vh;
        // margin: 0;
        // background-color: #fd6711;
        // border-top-right-radius: 8px;
        // border-bottom-right-radius: 8px;
        // transition: all 10.5s;
        // .md-menu {
        //     margin-right: 10px;
        // }
         button {
                // border-color: $black;
                border-radius: 2px;
                // height: 3vmin;
                // margin: 0vmin 1vmin 0 0;
                // width: 30px;
                // margin-right: 10px;
                text-align: center;
                height: 100%;
                font-size: 22px !important;
                font-weight: 500;
                border: 1px solid blue !important;
                min-width: 2.4vw;
            }
        .ant-controls-primary {
            // transition: all 10.5s;
            // background-color: #313941;
            padding: 5px 15px;


            button {
                // border-color: $black;
                border-radius: 2px;
                height: 3vmin;
                margin: .3vmin 0 0 -100px;
            }
        }

        &.closed {
            display: none;
        }


    }
    .ant-clip {
        z-index: 20;
        .ant-controls-secondary {
            height: 5vh;
            .md-button.md-icon-button{
                margin:0;
            }
            .playPause{
                background-color: #4f4f51;
            }

            button {
                // border-color: $black;
                border-radius: 2px;
                // height: 3vmin;
                // margin: 0vmin 1vmin 0 0;
                // width: 30px;
                // margin-right: 10px;
                text-align: center;
                height: 100%;
                font-size: 22px !important;
                font-weight: 500;
                border: 1px solid blue !important;
                min-width: 2.4vw;
            }
            i{
                vertical-align: middle;
            }
        }
    }
    .outer {
      position: absolute;
      // width: 2000px;
      left: 10vw;
      // min-width: 100vw;
      z-index: 0;
      bottom: 0;
      // margin-left: -1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 36px;
    }

    .left__inner {
        // display: none !important;
      // background: goldenrod;
      
      padding: 0px 4vmin;
      flex: 1;
      transform: skew(45deg);
      display: flex;
      justify-content: flex-end;
      will-change: transform;
    }
    .right__inner {
        // display: none !important;
      // background: #222;
      
      padding: 0px 4vmin;
      flex: 1;
      transform: skew(45deg);
      will-change: transform;
    }
    .left__inner,
    .right__inner {
        white-space: nowrap;
        // width: 100vw;
        &.off{
            display: none;
        }
        &.on {
            display: block;
        }
    }

    .left__text,
    .right__text {
      transform: skew(-45deg);
      will-change: transform;
      span {
        color: white;
        // font-weight: 200;
        font-size: 1.5em;
        // text-transform: uppercase;
      }
    }

    .left__text {
      color: #3c3c3c;

    }

    .right__text {
      color: Goldenrod;
    }

    .ant-text-wrapper {
        height: 5vh;  
        // overflow: display;
        position: relative;
        white-space: nowrap;
        // min-width: 100vw;


    }
    .ant-text-wrapper .outer, .ant-text-wrapper.single-remove-active .outer {
     height: 100%;
     margin: 0;
    //  margin-bottom: .7vmin;
     // line-height: 50px;
     text-align: center;
     /* Starting position */
     -moz-transform:translateX(14.3%);
     -webkit-transform:translateX(14.3%);  
     transform:translateX(14.3%);
     /* Apply animation to this element */  
     -moz-animation: ant-text-wrapper 280s 0s linear infinite;
     -webkit-animation: ant-text-wrapper 280s 0s linear infinite;
     animation: ant-text-wrapper 280s 0s linear infinite;
     transform: translateZ(0)
    }
    /* Move it (define the animation) */
    @-moz-keyframes ant-text-wrapper {
     0%   { -moz-transform: translateX(14.3%); }
     100% { -moz-transform: translateX(-100%); }
    }
    @-webkit-keyframes ant-text-wrapper {
     0%   { -webkit-transform: translateX(14.3%); }
     100% { -webkit-transform: translateX(-100%); }
    }
    @keyframes ant-text-wrapper {
     0%   { 
         -moz-transform: translateX(14.3%); /* Firefox bug fix */
         -webkit-transform: translateX(14.3%); /* Firefox bug fix */
         transform: translateX(14.3%);       
     }
     100% { 
         -moz-transform: translateX(-100%); /* Firefox bug fix */
         -webkit-transform: translateX(-100%); /* Firefox bug fix */
         transform: translateX(-100%); 
     }
    }
    .ant-text-wrapper {
        .single{
            // min-width: 100vw;
            &.short{
                .left__inner, .right__inner {
                    white-space: nowrap;
                    // width: 30vw;
                    margin: 0 30vw 0 40vw;
                }
                -moz-animation: ant-text-wrapper 63s 0s linear infinite;
                -webkit-animation: ant-text-wrapper 63s 0s linear infinite;
                animation: ant-text-wrapper 63s 0s linear infinite;
                transform: translateZ(0);
                @keyframes ant-text-wrapper {
                 0%   { 
                     -moz-transform: translateX(100%); /* Firefox bug fix */
                     -webkit-transform: translateX(100%); /* Firefox bug fix */
                     transform: translateX(100%);       
                 }
                 100% { 
                     -moz-transform: translateX(-100%); /* Firefox bug fix */
                     -webkit-transform: translateX(-100%); /* Firefox bug fix */
                     transform: translateX(-100%); 
                 }
                }
            }
            &.long{
                -moz-animation: ant-text-wrapper 260s 0s linear infinite;
                -webkit-animation: ant-text-wrapper 260s 0s linear infinite;
                animation: ant-text-wrapper 260s 0s linear infinite;
                transform: translateZ(0);
                @keyframes ant-text-wrapper {
                 0%   { 
                     -moz-transform: translateX(100%); /* Firefox bug fix */
                     -webkit-transform: translateX(100%); /* Firefox bug fix */
                     transform: translateX(100%);       
                 }
                 100% { 
                     -moz-transform: translateX(-100%); /* Firefox bug fix */
                     -webkit-transform: translateX(-100%); /* Firefox bug fix */
                     transform: translateX(-100%); 
                 }
                }
            }

            // margin-left: 100vw;
            /* Move it (define the animation) */
            @-moz-keyframes ant-text-wrapper {
             0%   { -moz-transform: translateX(13%); }
             100% { -moz-transform: translateX(-105%); }
            }
            @-webkit-keyframes ant-text-wrapper {
             0%   { -webkit-transform: translateX(13%); }
             100% { -webkit-transform: translateX(-105%); }
            }
            @keyframes ant-text-wrapper {
             0%   { 
             -moz-transform: translateX(13%); /* Firefox bug fix */
             -webkit-transform: translateX(13%); /* Firefox bug fix */
             transform: translateX(13%);       
             }
             100% { 
             -moz-transform: translateX(-105%); /* Firefox bug fix */
             -webkit-transform: translateX(-105%); /* Firefox bug fix */
             transform: translateX(-105%); 
             }
            }
        }
    }
}
    .paused, .outer:hover, .outer.single:hover {
        -webkit-animation-play-state: paused !important;
        -moz-animation-play-state: paused !important;
        -o-animation-play-state: paused !important;
         animation-play-state: paused !important;
    }
.ant-text-wrapper.closed {
        min-width: 0 !important;
        max-width: 0 !important;
        overflow: hidden !important;
        display: none;

    }
.ant-clip {
    margin:0; 
    // transform: scale(0.85);
    transition: all 1.2s;
}
.ant-wrapper {
    transition: all 1.2s;
    transition-delay: 1.2s;

}
// .sami-icon {
//     div {
//         width: 0;
//         overflow: hidden;
//         transition: all 1.2s;
//         transition-delay: 2.4s;
//     }
// }
// .sami-icon.closed {
//     div {
//         width: 100%;
//         overflow: hidden;
//         // transition: all 2.0s;
//         // transition-delay: 6.0s;
//     }
// }
 .sami-icon:focus, .md-icon:focus, span:focus, button:focus {
    outline: none;
    border: 0;
 }
.ant-clip.closed {
    padding-right: 8.88vw; 
    // opacity: 1;
    // transform: scale(2);
    .ant-controls-secondary {
        display: none !important;
    }
}
.ant-wrapper.closed {
    md-menu{
        opacity: 1;
    }
    // height: 0vh !important;
    // margin-bottom: 5vh;
    overflow: hidden;
    background: none;
    // display: none;
}

.notifications {
    md-button{
        img {
            max-height: 1vw;
            max-width: 2px !important;
        }
    }
}

.sami-list{
    background: none;
    padding: 8px 0 0 0;
}

.report-popup-dialog {
    width: 60%;
    height: 90%;
    max-height: 100%;
    .report-app-frame {
      height: 100%;
    }
    md-icon {
      margin: auto;
      color:black;
    }
    .report-header {
      background-color: $darkblue;
      // padding:10px 0;
      .report-close {
        color: white;
      }
      .header-text {
        color: white;
        padding-left: 10px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  
    .md-nav-bar {
      // background-color: $logo-orange !important;
      .md-button.md-accent {
        color: $logo-color !important;
      }
      md-nav-ink-bar {
        background-color: $logo-color;
        color: $logo-color;
      }
    }
    .pdf-view {
      background-color: rgb(63, 63, 63);
    }
    .report-week__nav{
      padding-right:20px;
    }
  }

