synchronize-chart-container, .sc-container  {
	width: 100%;
	md-card {
		min-height: 45vw;
		
		md-toolbar {
			background-color: $black;
			text-align: center;
			text-transform: capitalize;
		}
	}
}