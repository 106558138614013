.decode-container {
  display: flex;
  justify-content: center;
  padding: 16px;
  .decode-site__select{
    padding-right: 16px;
  }
  md-icon,
  .md-select-icon {
    color: black;
    margin: 0;
  }
  .md-select-value {
    border-bottom-color: black;
    .md-text {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .decode-block {
    width: 100%;
  }

  .decode-header {
    background-color: white;
    padding: 0 16px;
    margin-top: 10px;
    border-radius: 4px;
  }

  .decode-chart__container {
    md-card {
      margin: 16px 0;
      padding: 16px;
      height: 78vh;
    }
    .decode-chart__title {
      text-transform: uppercase;
      letter-spacing: 0.75px;
      font-size: 14px;
    }
  }

  .decode-chart__block {
    margin: 2px 8px;
    overflow: hidden;
  }

  md-progress-circular path {
    // stroke: white;
  }
}

.md-date-range-picker__month-year {
  md-icon {
    margin: 0;
    color: black;
  }
}
