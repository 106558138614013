@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700italic,900,900italic,300italic,300,100italic,100,700);
@import url("//fonts.googleapis.com/css?family=Arimo");
.fullscreen-chart iframe,
#myHistoryDialog-db-1 iframe {
  width: 100%;
  height: 60vh; }

.fullscreen-chart iframe,
#myHistoryDialog-db-2 iframe {
  width: 100%;
  height: 60vh; }

md-card iframe {
  height: 30vh; }

.loading-gears.text {
  text-align: center;
  margin-left: 30px;
  display: block; }

.loading-gears.img {
  width: 4vw;
  height: auto;
  position: relative;
  left: 45%;
  margin-top: 2vh;
  margin-bottom: 0.2vh; }

md-tabs-content-wrapper {
  min-height: 60vh; }

md-tab-canvas md-tab-item#md-active {
  background-color: #499fd4;
  color: white; }
  md-tab-canvas md-tab-item#md-active span {
    text-shadow: 2px 2px 5px Black; }

md-tab-canvas md-tab-item#md-active,
md-tabs md-ink-bar {
  color: #499FD4;
  background-color: #499FD4; }

.fullscreen-chart .md-button,
.fullscreen-chart .chart-views .md-button {
  background-color: #499ED4;
  width: 20%;
  color: white; }

.fullscreen-chart .md-button span {
  text-shadow: 2px 2px 2px black; }

.myHistoryDialog > md-dialog > md-tooolbar,
.md-dialog-container .card-heading.dashboard {
  max-height: 2vh; }

.myHistoryDialog > md-dialog > md-tooolbar,
.md-dialog-container .card-heading {
  max-height: 7vh; }

md-radio-group {
  text-transform: capitalize;
  padding: 5px 0; }

.modebar-group svg {
  display: unset;
  width: unset;
  height: unset; }

.md-dialog-container {
  z-index: 79 !important; }

aqs-chat {
  padding: 0 !important; }

md-backdrop.md-sidenav-backdrop {
  z-index: 998; }

.md-sidenav-right {
  background-color: #758185;
  overflow-y: scroll;
  z-index: 1001; }
  .md-sidenav-right md-content {
    padding: 0; }
    .md-sidenav-right md-content form {
      background-color: #313941; }
      .md-sidenav-right md-content form .row {
        width: 25vw;
        height: 20vw; }
        .md-sidenav-right md-content form .row textarea {
          margin: 5px 5px; }
    .md-sidenav-right md-content .send-button {
      width: 100px;
      margin: 0 0 0 5px;
      width: 309px;
      background-color: #0982CB;
      color: #EEF0F3;
      text-align: center;
      font-size: 25px;
      letter-spacing: 1px; }
      .md-sidenav-right md-content .send-button md-icon {
        margin: 0;
        margin-right: 2px;
        color: lightgray !important; }
  .md-sidenav-right .chat-header {
    background-color: #4964ae;
    height: 38px;
    display: flex;
    justify-content: center; }
    .md-sidenav-right .chat-header h1 {
      margin: 0;
      padding: 7px 0;
      font-size: 24px;
      color: white;
      text-shadow: 1px 1px 1px #375cc5;
      text-align: center; }
      .md-sidenav-right .chat-header h1 sup {
        font-size: 5px;
        vertical-align: super;
        letter-spacing: 0.5px;
        color: black;
        text-shadow: none;
        top: -1.5em; }
    .md-sidenav-right .chat-header img {
      width: 93px; }

.onbutton, .offbutton {
  margin: 0 !important;
  border-radius: 0; }

.onbutton {
  background-color: #fff;
  color: #292B2F; }

.sami-btn {
  font-size: 24px;
  text-align: center; }
  .sami-btn sup {
    font-size: 5px;
    vertical-align: super;
    letter-spacing: 0.5px;
    color: black;
    text-shadow: none;
    top: -1.5em; }

.offbutton {
  background-color: #292B2F;
  color: #EEF0F3; }

ul.collection {
  max-height: 76vh;
  overflow-y: scroll !important;
  margin: 0; }
  ul.collection.long {
    max-height: 88vh;
    padding: 0;
    width: 100% !important;
    padding: 0 8%; }
    ul.collection.long li.message:hover {
      background-color: #292B2F;
      color: #EEF0F3;
      position: relative; }
      ul.collection.long li.message:hover i {
        color: #EEF0F3;
        font-size: 1.5em;
        padding: 0;
        margin: 0; }
      ul.collection.long li.message:hover a {
        position: absolute;
        background-color: #fd6811;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        padding-top: 4%;
        color: white;
        font-weight: bold;
        font-size: 1.3em; }
  ul.collection.short {
    max-height: 76vh; }
  ul.collection div.chat-link {
    text-align: center;
    height: 100%; }
    ul.collection div.chat-link a {
      color: #292B2F; }
  ul.collection div.chat-link:hover {
    paddin: 100vmin;
    background-color: #375cc5; }
    ul.collection div.chat-link:hover a {
      color: white; }
  ul.collection .message-user {
    float: left;
    padding-right: 2px;
    margin-left: 2px;
    font-weight: bolder; }
  ul.collection .message-date {
    color: #ededed;
    font-size: 0.7em;
    margin-left: 3px;
    text-transform: capitalize;
    font-weight: normal;
    position: absolute;
    right: 10px;
    top: 3px; }
  ul.collection .message-content {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    word-wrap: break-word; }
  ul.collection .message-bubble {
    background-color: white;
    border-radius: 15px; }
  ul.collection .news {
    height: 100%; }
  ul.collection .news-icon-stats {
    color: #292B2F; }
  ul.collection .news-icon-alerts {
    color: #D41F22; }
  ul.collection li.news {
    overflow: hidden;
    display: block;
    width: 100%;
    max-height: 100%;
    margin: 2vmin 0;
    background-color: white;
    border-radius: 4px;
    padding: 1.5vmin; }
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    transition: all linear 2s; }
  ul.collection .news.ng-leave {
    transition: all linear 0.3s; }
  ul.collection .news.ng-leave,
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    transition: all linear 1s; }
  ul.collection .news.ng-leave .news-icon,
  ul.collection .news.ng-move .news-icon,
  ul.collection .news.ng-enter .news-icon {
    transition: all linear 1s; }
  ul.collection .news.ng-enter-stagger {
    transition-delay: 0.2s;
    /* As of 1.4.4, this must always be set: it signals ngAnimate
        to not accidentally inherit a delay property from another CSS class */
    transition-duration: 0s; }
  ul.collection .news.ng-leave.ng-leave-active,
  ul.collection .news.ng-move,
  ul.collection .news.ng-enter {
    opacity: 0; }
  ul.collection .news.ng-leave,
  ul.collection .news.ng-move.ng-move-active,
  ul.collection .news.ng-enter.ng-enter-active {
    opacity: 1; }

.triangle-right {
  position: relative;
  padding: 5px;
  margin: 0.7em 0 0.7em;
  color: #fff;
  background: #313941;
  /* default background for browsers without gradient support */
  /* css3 */
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#2e88c4), to(#313941));
  background: -moz-linear-gradient(#2e88c4, #313941);
  background: -o-linear-gradient(#2e88c4, #313941);
  background: linear-gradient(#2e88c4, #313941);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

/* Variant : for top positioned triangle
------------------------------------------ */
.triangle-right.top {
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#313941), to(#2e88c4));
  background: -moz-linear-gradient(#313941, #2e88c4);
  background: -o-linear-gradient(#313941, #2e88c4);
  background: linear-gradient(#313941, #2e88c4); }

/* Variant : for left positioned triangle
------------------------------------------ */
.triangle-right.left {
  margin-left: -10px;
  background: #313941;
  margin-right: 30px; }

/* Variant : for right positioned triangle
------------------------------------------ */
.triangle-right.right {
  margin-right: 30px;
  background: #375cc5;
  margin-left: -10px; }

.triangle-right:after {
  content: "";
  position: absolute;
  bottom: -20px;
  /* value = - border-top-width - border-bottom-width */
  left: 50px;
  /* controls horizontal position */
  border-width: 20px 0 0 20px;
  /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #375cc5 transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0; }

/* Variant : top
------------------------------------------ */
.triangle-right.top:after {
  top: -20px;
  /* value = - border-top-width - border-bottom-width */
  right: 50px;
  /* controls horizontal position */
  bottom: auto;
  left: auto;
  border-width: 20px 20px 0 0;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #375cc5; }

/* Variant : left
------------------------------------------ */
.triangle-right.left:after {
  top: 16px;
  left: -30px;
  /* value = - border-left-width - border-right-width */
  bottom: auto;
  border-width: 15px 30px 0 0;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #313941; }

/* Variant : right
------------------------------------------ */
.triangle-right.right:after {
  top: 16px;
  right: -30px;
  /* value = - border-left-width - border-right-width */
  bottom: auto;
  left: auto;
  border-width: 15px 0 0 30px;
  /* vary these values to change the angle of the vertex */
  border-color: transparent #375cc5; }

.types button {
  margin: 0 !important;
  width: 100%; }

.types .type-all {
  padding: 0 3.5%;
  font-size: 20px;
  background: #375cc5;
  border-radius: 0;
  color: white;
  font-weight: 500;
  min-width: 50px;
  text-transform: capitalize !important; }

.types .stat-type {
  font-size: 20px;
  background: #292B2F;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .alert-type {
  font-size: 20px;
  background: #D41F22;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .mgt-type {
  font-size: 20px;
  background: #00B04F;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.types .industry-type {
  font-size: 20px;
  background: #558DD5;
  border-radius: 0;
  color: white;
  font-weight: 500;
  text-transform: capitalize !important; }

.news-card {
  color: white; }
  .news-card .toggle-icon {
    margin-left: auto;
    vertical-align: middle;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .news-card:focus {
    outline: none; }
  .news-card .news-header {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px; }

.news-stats {
  background: #292B2F; }

.news-alerts {
  background: #D41F22; }

.news-management {
  background: #00B04F; }

.news-industry {
  background: #558DD5; }

md-badge {
  margin: 0 0 0 8px;
  background-color: #259b24;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 16px; }

.new-chat {
  background-color: red; }

.ANN {
  background-color: #758185; }

.ann-width {
  min-width: 200px !important;
  width: 85vw !important;
  max-width: 640px !important; }

.no-news {
  margin-top: 15px;
  color: #EEF0F3;
  text-transform: capitalize; }
  .no-news .no-news-icon {
    line-height: 2; }
  .no-news .no-news-text {
    font-size: 16px;
    font-weight: 500; }

.view-container {
  width: 100%; }
  .view-container .md-dialog-container {
    z-index: -80; }
  .view-container .bg-blue {
    background-color: steelblue; }
  .view-container .bg-green {
    background-color: #2ca02c; }
  .view-container .bg-darkgreen {
    background-color: #01584A; }
  .view-container .bg-orange {
    background-color: #ff7f0e; }
  .view-container .bg-red {
    background-color: #d62728; }
  .view-container .bg-yellow {
    background-color: #F0D705; }
  .view-container .bg-purple {
    background-color: #9467bd; }
  .view-container .bg-blue {
    background-color: steelblue; }
  .view-container .hide {
    display: none !important; }

.draglayer {
  margin-top: -20px !important; }

td.pvtTotal.rowTotal {
  display: none; }

td.pvtTotal.colTotal {
  display: none; }

th.pvtTotalLabel {
  display: none; }

td.pvtGrandTotal {
  display: none; }

.todayschart {
  width: 80vw;
  height: 80vh; }

.gc {
  max-height: 20vh;
  height: 50%;
  background-color: #313941; }

.system-efficiency-container .system-efficiency-heading {
  height: 40px;
  margin: 0;
  background-color: #313941; }
  .system-efficiency-container .system-efficiency-heading h2 {
    text-align: center;
    background-color: #313941;
    color: white;
    font-size: 20px;
    margin: 0;
    letter-spacing: 0.4px; }

.system-efficiency-container .wet-well-button {
  padding-left: 10px; }

.dashboard-container {
  z-index: 3 !important;
  width: 100%;
  overflow-y: scroll !important;
  padding-top: 42px; }
  .dashboard-container md-content {
    background-color: #758185; }
  .dashboard-container .dashboard-charts-container {
    margin: 0; }
  .dashboard-container .chart-gauge {
    width: 100%; }
  .dashboard-container #FlowValue-gague .chart-filled {
    fill: #1f77b4; }
  .dashboard-container #SpeedValue-gague .chart-filled,
  .dashboard-container #SuctionValue-gague .chart-filled {
    fill: #2ca02c; }
  .dashboard-container #TanklevelValue-gague .chart-filled {
    fill: #2ca02c; }
  .dashboard-container #ValveValue-gague .chart-filled,
  .dashboard-container #Well_LevelValue-gague .chart-filled {
    fill: #ff7f0e; }
  .dashboard-container #PressureValue-gague .chart-filled {
    fill: #ff7f0e; }
  .dashboard-container #HeadValue-gague .chart-filled {
    fill: #d62728; }
  .dashboard-container #CostValue-gague .chart-filled {
    fill: #9467bd; }
  .dashboard-container .chart-filled {
    fill: steelblue; }
  .dashboard-container .chart-empty {
    fill: #dedede; }
  .dashboard-container .needle,
  .dashboard-container .needle-center {
    fill: #464a4f; }
  .dashboard-container svg {
    font: 10px sans-serif; }
  .dashboard-container g.c3-chart-arcs {
    width: 70%;
    position: relative; }
  .dashboard-container text.c3-gauge-value {
    font-size: 2em !important;
    display: none; }
  .dashboard-container .nv-legend-symbol {
    display: none !important; }
  .dashboard-container .legend-color-guide {
    display: none !important; }
    .dashboard-container .legend-color-guide div {
      background-color: none !important; }
  .dashboard-container .dashboard-gauge-sync-container .md-active {
    background-color: #313941;
    color: #EEF0F3; }
  .dashboard-container .dashboard-gauge-sync-container md-tabs-canvas {
    background-color: #eef0f3;
    border: none; }
  .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container {
    height: 33.3333333vh;
    text-align: center; }
    .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges {
      text-align: center;
      height: 25vh; }
      .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges .gauge-view {
        max-height: 5vh; }
        .dashboard-container .dashboard-gauge-sync-container .dashboard-gauge-container .gauges .gauge-view span {
          display: block;
          text-align: center;
          line-height: 2px;
          padding: 10%; }
  .dashboard-container .dashboard-gauge-sync-container .gauge {
    text-align: center;
    color: #EEF0F3;
    height: 17vh; }
    .dashboard-container .dashboard-gauge-sync-container .gauge .needle,
    .dashboard-container .dashboard-gauge-sync-container .gauge .needle-center {
      fill: #EEF0F3; }
    .dashboard-container .dashboard-gauge-sync-container .gauge span:first-child {
      line-height: 10%;
      padding: 2%; }
    .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label {
      margin-bottom: 18px;
      display: block;
      text-align: center; }
      .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span {
        display: inline-block;
        text-align: center;
        padding: 0;
        height: 0;
        font-weight: bolder;
        width: 33%; }
        .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.left {
          text-align: left;
          text-transform: uppercase;
          font-size: 1.2vmin;
          font-weight: 600; }
        .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.center {
          text-align: center;
          font-size: 1.3vmin;
          font-weight: 600; }
        .dashboard-container .dashboard-gauge-sync-container .gauge .gauge-label span.right {
          text-align: right;
          font-size: 1.2vmin;
          font-weight: 700; }
  .dashboard-container .dashboard-gauge-sync-container .gauge-units {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 400;
    padding: 15% 0 15% 0;
    color: #EEF0F3; }
  .dashboard-container md-card md-icon {
    color: white;
    float: left; }

#history-view-system-efficiency h2 {
  background-color: #313941;
  color: #EEF0F3;
  text-align: center;
  margin: 0; }

#myStaticDialog-sc-0 .js-plotly-plot,
#myStaticDialog-sc-1 .js-plotly-plot,
#myStaticDialog-sc-2 .js-plotly-plot {
  overflow: hidden; }
  #myStaticDialog-sc-0 .js-plotly-plot .plotly,
  #myStaticDialog-sc-1 .js-plotly-plot .plotly,
  #myStaticDialog-sc-2 .js-plotly-plot .plotly {
    margin-top: 25px; }

.button-on {
  background-color: #089201;
  -webkit-border-radius: 10px;
  border-radius: 50%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 12px;
  text-transform: capitalize;
  /*   padding: 5px 10px; */
  text-align: center;
  text-decoration: none;
  width: 25px;
  height: 25px;
  padding: 2px 1px;
  margin: 5px 10px; }

@keyframes glowing {
  0% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201; }
  50% {
    background-color: #089201;
    box-shadow: 0 0 20px #089201; }
  100% {
    background-color: #089201;
    box-shadow: 0 0 1.5px #089201; } }

.button-on {
  -webkit-animation: glowing 2000ms infinite;
  -moz-animation: glowing 2000ms infinite;
  -o-animation: glowing 2000ms infinite;
  animation: glowing 2000ms infinite; }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-minimal {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  transform: translateZ(0); }

.odometer.odometer-auto-theme
.odometer-digit
.odometer-value.odometer-last-value,
.odometer.odometer-theme-minimal
.odometer-digit
.odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating
.odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down
.odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating
.odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.flow-prediction-container {
  max-height: 970px; }
  .flow-prediction-container .flow-prediction-heading {
    height: 40px;
    margin: 0;
    background-color: #313941; }
    .flow-prediction-container .flow-prediction-heading h2 {
      text-align: center;
      background-color: #313941;
      color: white;
      font-size: 20px;
      letter-spacing: 1px;
      margin: 0;
      padding-top: 10px; }

.md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.md-whiteframe-12dp {
  box-shadow: 0px 15px 16px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.inner-wrapper {
  margin-left: 1.3px; }

.notificationToast md-toast._md-top._md-center,
.notificationToast md-toast._md-bottom._md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.status-toast.md-default-theme .md-toast-content,
.status-toast .md-toast-content {
  background-color: #D41F22;
  color: #EEF0F3;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  text-align: center;
  margin-top: 0;
  padding: 0;
  max-height: 43px !important; }

.rta-info {
  font-size: calc(0.35rem + 1.45vmin) !important;
  float: right; }

.rta-load {
  font-size: larger;
  font-weight: 700; }

.info-bar {
  background-color: #313941 !important;
  min-height: 45px !important; }

.info-h1 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  padding-top: 12px; }

.info-data {
  font-size: 13px;
  color: #eef0f3;
  margin: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 18px; }
  .info-data img {
    height: 30px;
    width: 50px;
    vertical-align: sub;
    padding-right: 5px; }
  .info-data .info-heading-1 {
    margin-top: 0;
    text-align: center;
    font-size: large;
    font-weight: 700; }

.info-toolbar {
  min-height: 0; }

.info-content {
  background-color: #313941 !important; }
  .info-content .rta-update {
    color: #EEF0F3;
    position: fixed;
    bottom: 0;
    left: 5%;
    font-weight: 500; }

.info-heading {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
  font-size: large;
  font-weight: 700; }

.close-info {
  background-image: linear-gradient(to right, #6990fa, #5e81e0, #5372c6, #4863ad, #3e5594);
  width: 95%;
  color: #EEF0F3; }
  .close-info:hover {
    background-color: #CE5656 !important;
    color: #EEF0F3 !important; }

.dashboard-card {
  background-color: #EEF0F3; }
  .dashboard-card .first-card {
    text-align: center;
    height: 149px; }
    .dashboard-card .first-card .flow-text {
      background-color: #313941;
      color: #EEF0F3;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder; }
    .dashboard-card .first-card .flow-unit {
      background-color: #313941;
      color: #EEF0F3;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder;
      display: inline-flex;
      text-align: center; }
      .dashboard-card .first-card .flow-unit md-switch {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: fit-content; }
      .dashboard-card .first-card .flow-unit md-switch.md-checked.md-warn .md-thumb {
        background-color: #499FD4; }
      .dashboard-card .first-card .flow-unit md-switch.md-checked.md-warn .md-bar {
        background-color: rgba(73, 159, 212, 0.5); }
      .dashboard-card .first-card .flow-unit :focus {
        outline: none; }
    .dashboard-card .first-card .pressure-text,
    .dashboard-card .first-card .pressure-unit {
      color: #EEF0F3;
      background-color: #CB7909;
      text-align: center;
      margin: 0;
      padding: 4px 0;
      font-size: 19px;
      font-weight: bolder; }
    .dashboard-card .first-card .flow-value,
    .dashboard-card .first-card .pressure-value {
      font-size: 56px;
      letter-spacing: 2px;
      color: #4d5322; }
      .dashboard-card .first-card .flow-value .odometer.odometer-theme-minimal .odometer-value,
      .dashboard-card .first-card .pressure-value .odometer.odometer-theme-minimal .odometer-value {
        font-size: calc(1.4rem + 5.45vmin); }
    .dashboard-card .first-card .value-box {
      background-color: rgba(3, 48, 75, 0.03); }
  .dashboard-card .second-card {
    height: 149px;
    overflow: scroll; }
    .dashboard-card .second-card .second-card-heading {
      text-align: center;
      color: #EEF0F3;
      font-weight: bolder;
      font-size: 18px;
      background-color: #313941;
      margin: 0;
      padding: 4px 0; }
    .dashboard-card .second-card .value-box {
      background-color: #499FD4;
      color: #EEF0F3;
      text-align: center; }
      .dashboard-card .second-card .value-box .dmg-value {
        font-size: 56px;
        letter-spacing: 2px;
        margin: 0 10%; }
      .dashboard-card .second-card .value-box .dmg-unit {
        font-size: calc(0.35rem + 1.75vmin);
        position: relative;
        top: 20%; }
    .dashboard-card .second-card .pump-btn {
      background-color: #499FD4;
      -webkit-border-radius: 10px;
      border-radius: 50%;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      font-size: 9px;
      font-weight: 700;
      letter-spacing: 0.1px;
      text-transform: uppercase;
      /*   padding: 5px 10px; */
      text-align: center;
      text-decoration: none;
      width: 30px;
      height: 30px;
      margin: 5px 8px; }
    .dashboard-card .second-card .pump-btn-on {
      background-color: #D41F22;
      backface-visibility: hidden; }
    .dashboard-card .second-card .second-card-footer {
      background-color: #313941;
      color: #EEF0F3;
      padding: 6px 0;
      font-weight: 500; }
  .dashboard-card .third-card {
    max-height: 149px;
    overflow: scroll;
    background-color: transparent; }
    .dashboard-card .third-card .third-card-heading {
      height: auto;
      text-align: center;
      color: #EEF0F3;
      font-weight: bolder;
      font-size: calc(0.35rem + 1.45vmin);
      text-transform: capitalize;
      background-color: #313941;
      margin: 0;
      padding: 4px 0;
      letter-spacing: 0.4px; }
      .dashboard-card .third-card .third-card-heading .rta-info {
        font-size: calc(0.35rem + 1.45vmin) !important;
        float: right; }
    .dashboard-card .third-card .rta-box-long {
      background-color: #499FD4; }
    .dashboard-card .third-card .rta-text {
      background-color: white;
      font-size: larger;
      font-weight: 700;
      max-height: 17vh;
      color: black;
      text-align: center;
      line-height: 123px; }
    .dashboard-card .third-card .block-text {
      background-color: #3E5594;
      font-size: 16px;
      font-weight: 500;
      color: white;
      padding: 1.5%;
      word-wrap: break-word; }
    .dashboard-card .third-card table.md-table:not(.md-row-select)
td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    .dashboard-card .third-card table.md-table:not(.md-row-select)
th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 0; }
    .dashboard-card .third-card table.md-table thead.md-head > tr.md-row {
      height: 24px !important; }
    .dashboard-card .third-card table.md-table th.md-column {
      color: white !important;
      font-weight: 700 !important;
      text-align: center !important; }
    .dashboard-card .third-card td.md-cell:nth-child(even),
    .dashboard-card .third-card th.md-column:nth-child(even) {
      background-color: #CB7909 !important;
      color: white !important;
      font-weight: 500;
      text-align: center;
      padding: 0 !important;
      font-size: calc(0.3rem + 1.1vmin); }
    .dashboard-card .third-card td.md-cell:nth-child(odd),
    .dashboard-card .third-card th.md-column:nth-child(odd) {
      background-color: #499FD4 !important;
      color: white !important;
      font-weight: 500;
      font-size: calc(0.3rem + 1.1vmin);
      padding: 0 !important;
      letter-spacing: 0.4px; }
    .dashboard-card .third-card table.md-table:not(.md-row-select) th.md-column:first-child {
      padding: 0; }
    .dashboard-card .third-card table.md-table th.md-column:last-child {
      padding: 0; }
    .dashboard-card .third-card .md-row .md-column,
    .dashboard-card .third-card .md-row .md-cell {
      text-align: center !important;
      font-size: 14px;
      letter-spacing: 0.8px; }
    .dashboard-card .third-card table.md-table tbody.md-body > tr.md-row,
    .dashboard-card .third-card table.md-table tfoot.md-foot > tr.md-row {
      height: 21px !important;
      text-transform: capitalize;
      text-align: left; }

md-icon {
  color: white; }

.nogauge-card .error-content {
  display: inherit; }
  .nogauge-card .error-content .error-icon {
    color: red;
    font-size: 20px; }
  .nogauge-card .error-content .error-text {
    font-size: larger;
    font-weight: 700;
    color: black;
    margin-left: 5px; }

.se-load {
  display: grid;
  margin: 8%; }
  .se-load .text {
    text-align: center;
    font-weight: 500;
    font-size: 16px; }
  .se-load .img {
    width: 80px;
    height: auto;
    margin-left: 12px; }

.se-content {
  padding: 120px 0 120px 0; }
  .se-content .error-icon {
    color: red;
    font-size: 20px; }
  .se-content .error-text {
    font-size: larger;
    font-weight: 700;
    color: black;
    margin-left: 5px; }

.frame-tab,
.frame-expand,
.frame-history {
  background: url("https://d2a5hmbghmbgtd.cloudfront.net/webfonts/atlas_spinner.svg") center center no-repeat; }

.day-content {
  margin: 0 8px; }
  .day-content .dash-on-peak {
    color: #EEF0F3;
    font-weight: 500;
    padding-left: 8px;
    font-size: 19px; }
  .day-content .dash-off-peak {
    color: #EEF0F3;
    font-weight: 500;
    padding-left: 8px;
    font-size: 19px; }
  .day-content .day-forecast-header {
    min-height: 40px !important;
    max-height: 40px;
    border-radius: 4px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #313941 !important; }
    .day-content .day-forecast-header .day-forecast-title {
      font-size: calc(0.35rem + 1.85vmin);
      color: #EEF0F3;
      font-weight: 500; }
      .day-content .day-forecast-header .day-forecast-title .ss-value {
        background-color: #f44336;
        border-radius: 4px;
        letter-spacing: 1px;
        padding: 2px; }
      .day-content .day-forecast-header .day-forecast-title .ss-unit {
        font-size: calc(0.35rem + 1vmin); }
    .day-content .day-forecast-header .day-forecast-icon {
      text-align: right;
      display: block !important;
      color: #EEF0F3; }
      .day-content .day-forecast-header .day-forecast-icon .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
        .day-content .day-forecast-header .day-forecast-icon .toggle-icon:focus {
          outline: none; }
    .day-content .day-forecast-header:focus {
      outline: none; }
  .day-content .content-block {
    padding: 0; }
    .day-content .content-block md-card {
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px; }
    .day-content .content-block .ecard-0 {
      margin-right: 8px; }
      @media (max-width: 959px) and (min-width: 600px) {
        .day-content .content-block .ecard-0 {
          margin: 0 !important; } }
    .day-content .content-block .ecard-1 {
      margin-left: 8px; }
      @media (max-width: 959px) and (min-width: 600px) {
        .day-content .content-block .ecard-1 {
          margin: 0 !important; } }
  .day-content .day-card-param {
    font-size: calc(0.35rem + 1.65vmin);
    color: #EEF0F3;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-weight: bolder;
    letter-spacing: 0.3px; }
    .day-content .day-card-param md-icon {
      margin: auto;
      padding-left: 8px; }
  .day-content .day-card-value {
    font-size: calc(1rem + 3vh);
    text-align: center; }
  .day-content .day-card-unit {
    color: #EEF0F3;
    text-align: center;
    margin: 0;
    padding: 0.5vmin 0;
    font-size: calc(0.35rem + 1.35vmin);
    font-weight: bolder; }
  .day-content .unit-symbol {
    font-size: calc(0.45rem + 1.3vmin); }
  .day-content .block-unit {
    color: #EEF0F3;
    text-align: center;
    margin: 0;
    padding: 0.45vmin 0;
    font-size: calc(0.35rem + 1.35vmin);
    font-weight: bolder; }
  .day-content .dd-0 {
    background-color: steelblue; }
  .day-content .dd-1 {
    background-color: #CB7909; }

.toggled {
  transform: rotate(0deg) !important; }

.pump-aging-content {
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  display: flex;
  margin: 0 8px; }
  .pump-aging-content md-card {
    margin: 0; }
  .pump-aging-content .pump-aging-header-border {
    border-bottom: 1px #EEF0F3 solid; }
  .pump-aging-content .pump-aging-header {
    min-height: 40px !important;
    max-height: 40px;
    border-radius: 4px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #313941 !important; }
    .pump-aging-content .pump-aging-header md-icon {
      margin: 0 0 0 8px; }
    .pump-aging-content .pump-aging-header:focus {
      outline: none; }
    .pump-aging-content .pump-aging-header .pump-aging-title {
      font-size: calc(0.35rem + 1.85vmin);
      color: #EEF0F3;
      font-weight: 500; }
      .pump-aging-content .pump-aging-header .pump-aging-title img {
        height: 30px;
        width: 50px;
        vertical-align: sub; }
      .pump-aging-content .pump-aging-header .pump-aging-title:focus {
        outline: none; }
      .pump-aging-content .pump-aging-header .pump-aging-title .pump-name-logo {
        padding-left: 15px; }
      .pump-aging-content .pump-aging-header .pump-aging-title .flip-switch {
        padding-left: 10px; }
        .pump-aging-content .pump-aging-header .pump-aging-title .flip-switch md-switch {
          margin: 0;
          width: fit-content;
          padding: 0 10px;
          height: fit-content; }
        .pump-aging-content .pump-aging-header .pump-aging-title .flip-switch md-switch.md-checked .md-thumb {
          background-color: #ff9800; }
        .pump-aging-content .pump-aging-header .pump-aging-title .flip-switch md-switch.md-checked .md-bar {
          background-color: #ff990092; }
    .pump-aging-content .pump-aging-header .aging-icon {
      text-align: right;
      display: block !important;
      color: #EEF0F3; }
      .pump-aging-content .pump-aging-header .aging-icon:focus {
        outline: none; }
      .pump-aging-content .pump-aging-header .aging-icon .toggle-icon {
        margin-left: auto;
        vertical-align: middle;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        transition: -webkit-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  .pump-aging-content .pump-aging-card {
    background-color: #313941;
    display: block; }
    @media (max-width: 1920px) and (min-width: 1280px) {
      .pump-aging-content .pump-aging-card {
        flex: 1 0 14%; } }
    .pump-aging-content .pump-aging-card .pump-name-header {
      background-color: #2E3F6E;
      font-size: calc(0.35rem + 1.45vmin);
      color: #EEF0F3;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase; }
    .pump-aging-content .pump-aging-card .color-green {
      background-color: green;
      color: #EEF0F3; }
    .pump-aging-content .pump-aging-card .color-red {
      background-color: red;
      color: #EEF0F3; }
    .pump-aging-content .pump-aging-card .color-grey {
      background-color: grey;
      color: #EEF0F3; }
    .pump-aging-content .pump-aging-card .color-yellow {
      background-color: #ffd900;
      color: #292B2F; }
    .pump-aging-content .pump-aging-card .name {
      margin-right: 10px;
      font-weight: 500;
      font-size: 16px; }
    .pump-aging-content .pump-aging-card .pump-value {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 500; }
  .pump-aging-content .pump-age-block {
    background-color: #EEF0F3; }
    .pump-aging-content .pump-age-block .age-value {
      font-size: calc(1rem + 1.5vw);
      letter-spacing: 2px;
      font-weight: 500; }
    .pump-aging-content .pump-age-block .counter-multi {
      vertical-align: sub;
      font-size: 16px;
      font-weight: 500; }
    .pump-aging-content .pump-age-block .pump-age-text {
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize; }
  .pump-aging-content .age-pump-run {
    text-align: center;
    background-color: #CB7909;
    color: #EEF0F3;
    font-weight: 500;
    text-transform: capitalize; }
  .pump-aging-content .age-pump-value {
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #EEF0F3; }
    .pump-aging-content .age-pump-value hr {
      border-top: 2px solid black;
      width: 12px; }
    .pump-aging-content .age-pump-value .age-value {
      font-size: calc(1rem + 1vw);
      letter-spacing: 2px;
      font-weight: 400; }
    .pump-aging-content .age-pump-value .age-year {
      vertical-align: sub;
      text-transform: lowercase; }
  .pump-aging-content .flip-container {
    perspective: 1000px;
    background-color: #313941; }
  .pump-aging-content .flip-back {
    transform: rotateY(180deg); }
    .pump-aging-content .flip-back:hover {
      transform: rotateY(0deg); }
  .pump-aging-content .flip-front {
    transform: rotateY(0deg); }
    .pump-aging-content .flip-front:hover {
      transform: rotateY(180deg); }
  .pump-aging-content .flip-container,
  .pump-aging-content .front,
  .pump-aging-content .back {
    width: 100%;
    height: 115px; }
  .pump-aging-content .flipper {
    transition: 0.9s;
    transform-style: preserve-3d;
    position: relative; }
  .pump-aging-content .front,
  .pump-aging-content .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  .pump-aging-content .front {
    transform: rotateY(0deg); }
  .pump-aging-content .back {
    transform: rotateY(180deg);
    background-color: #9e9e9e;
    color: white; }
  .pump-aging-content .back-on {
    background-color: #3E5594; }

.demand-planner-container .demand-planner-heading {
  min-height: 40px !important;
  max-height: 40px;
  border-radius: 4px;
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #313941 !important; }
  .demand-planner-container .demand-planner-heading md-icon {
    margin: 0 0 0 8px; }
  .demand-planner-container .demand-planner-heading:focus {
    outline: none; }
  .demand-planner-container .demand-planner-heading .title {
    font-size: calc(0.35rem + 1.85vmin);
    color: #EEF0F3;
    font-weight: 500; }
    .demand-planner-container .demand-planner-heading .title img {
      height: 30px;
      width: 50px;
      vertical-align: sub; }
    .demand-planner-container .demand-planner-heading .title:focus {
      outline: none; }
    .demand-planner-container .demand-planner-heading .title .pump-name-logo {
      padding-left: 15px; }
  .demand-planner-container .demand-planner-heading .switch-icon {
    text-align: right;
    display: block !important;
    color: #EEF0F3; }
    .demand-planner-container .demand-planner-heading .switch-icon:focus {
      outline: none; }
    .demand-planner-container .demand-planner-heading .switch-icon .toggle-icon {
      margin-left: auto;
      vertical-align: middle;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform 0.6s ease-in-out;
      transition: -webkit-transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out;
      transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }

.blink {
  -webkit-animation: glow 2000ms infinite;
  -moz-animation: glow 2000ms infinite;
  -o-animation: glow 2000ms infinite;
  animation: glow 2000ms infinite;
  backface-visibility: hidden; }

@keyframes glow {
  0% {
    stroke-opacity: 0.2; }
  25% {
    stroke-opacity: 0.3; }
  50% {
    stroke-opacity: 0.5; }
  75% {
    stroke-opacity: 0.6; }
  100% {
    stroke-opacity: 0.7; } }

.scatterlayer .points .point {
  -webkit-animation: glow 2000ms infinite;
  -moz-animation: glow 2000ms infinite;
  -o-animation: glow 2000ms infinite;
  animation: glow 2000ms infinite;
  backface-visibility: hidden; }

/* entire container, keeps perspective */
.pump-agis-nav-bar .md-nav-bar md-nav-ink-bar {
  background-color: #2E3F6E; }

.pump-agis-nav-bar .md-nav-bar .md-button.md-active {
  color: #2E3F6E; }

.pump-agis-nav-bar .note-btn {
  position: absolute;
  right: 47%;
  z-index: 100;
  margin: 0;
  padding: 0;
  background-color: #4964ae;
  color: white;
  min-height: unset;
  min-width: unset;
  line-height: unset; }
  .pump-agis-nav-bar .note-btn:hover {
    background-color: #2E3F6E; }
  .pump-agis-nav-bar .note-btn md-icon {
    margin: 8px; }

.rta-saving__block {
  padding-top: 10px;
  background: white; }
  .rta-saving__block .rta-saving__block-title {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px; }
  .rta-saving__block .rta-saving__block-perc {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 4px; }
  .rta-saving__block .rta-saving__block-cost {
    font-weight: 500;
    font-size: 18px; }

.rta-trd__mark {
  color: grey;
  font-size: 9px;
  text-decoration: none; }

.decode-container {
  display: flex;
  justify-content: center;
  padding: 16px; }
  .decode-container .decode-site__select {
    padding-right: 16px; }
  .decode-container md-icon,
  .decode-container .md-select-icon {
    color: black;
    margin: 0; }
  .decode-container .md-select-value {
    border-bottom-color: black; }
    .decode-container .md-select-value .md-text {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500; }
  .decode-container .decode-block {
    width: 100%; }
  .decode-container .decode-header {
    background-color: white;
    padding: 0 16px;
    margin-top: 10px;
    border-radius: 4px; }
  .decode-container .decode-chart__container md-card {
    margin: 16px 0;
    padding: 16px;
    height: 78vh; }
  .decode-container .decode-chart__container .decode-chart__title {
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-size: 14px; }
  .decode-container .decode-chart__block {
    margin: 2px 8px;
    overflow: hidden; }

.md-date-range-picker__month-year md-icon {
  margin: 0;
  color: black; }

.note-container {
  padding-top: 50px; }
  .note-container md-icon {
    margin: 0 10px; }
  .note-container .pagination-table {
    color: white !important;
    background-color: #2E3F6E !important;
    font-size: 12px;
    height: 40px; }
    .note-container .pagination-table md-icon {
      margin: 0;
      color: white; }
    .note-container .pagination-table md-select.md-table-select {
      margin: 20px 0 26px 0; }
    .note-container .pagination-table .md-button[disabled] md-icon {
      color: red !important; }
  .note-container md-select.md-table-select > .md-select-value > span > .md-text {
    color: #EEF0F3;
    font-weight: 500; }
  .note-container table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
    background-color: #e6e5e5 !important; }
  .note-container table.md-table tbody.md-body > tr.md-row > td {
    font-weight: 500; }
  .note-container table.md-table th.md-column {
    color: #292B2F;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500; }
  .note-container table.md-table thead.md-head > tr.md-row {
    height: 26px; }
  .note-container .no-results {
    padding: 0 16px; }
  .note-container .analysis-chart iframe {
    height: 60vh; }
  .note-container .note-tb-body md-icon {
    color: #000; }
  .note-container .note-tb-body .comment-count {
    vertical-align: text-top;
    font-size: 12px;
    background-color: #f44336;
    padding: 4px;
    border-radius: 50%;
    color: white;
    font-weight: 500;
    margin-left: -10px;
    margin-bottom: -20px; }
  .note-container .notification-header {
    background-color: #2E3F6E;
    color: white;
    height: 40px; }
    .note-container .notification-header .expand-icon {
      margin-left: 10px; }
    .note-container .notification-header .notification-title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.2px;
      margin-left: 5px; }
  .note-container .md-table-pagination > .buttons {
    margin-right: 40px; }
  .note-container .catalog-icon {
    margin-right: 10px; }
  .note-container md-content {
    background: none; }

.edit-note-dialog {
  width: 600px !important;
  height: fit-content !important; }
  .edit-note-dialog md-dialog-content {
    width: 600px !important;
    height: 60vh !important; }
  .edit-note-dialog form {
    overflow: unset; }
  .edit-note-dialog .edit-note-dialog-headar {
    background-color: #313941;
    color: white; }
    .edit-note-dialog .edit-note-dialog-headar md-icon {
      margin: 0 8px; }
    .edit-note-dialog .edit-note-dialog-headar .note-action-headar {
      min-height: 50px !important; }
    .edit-note-dialog .edit-note-dialog-headar .edit-note-header-text {
      font-size: 17px;
      padding-left: 10px;
      font-weight: 500; }
    .edit-note-dialog .edit-note-dialog-headar .note-search-headar {
      background-color: white;
      min-height: 50px !important;
      border: 2px #03a9f4 solid;
      border-radius: 2px; }
      .edit-note-dialog .edit-note-dialog-headar .note-search-headar md-icon {
        color: black;
        margin: auto; }
      .edit-note-dialog .edit-note-dialog-headar .note-search-headar .md-datepicker-input {
        min-width: 75px; }
      .edit-note-dialog .edit-note-dialog-headar .note-search-headar md-input-container .md-errors-spacer {
        min-height: 18px !important; }
      .edit-note-dialog .edit-note-dialog-headar .note-search-headar .apply-icon {
        width: 23px;
        height: 23px;
        min-height: 23px;
        padding: 0;
        background-color: #03a9f4 !important; }
        .edit-note-dialog .edit-note-dialog-headar .note-search-headar .apply-icon md-icon {
          color: white;
          font-size: 18px;
          line-height: 1.3;
          margin: auto; }
      .edit-note-dialog .edit-note-dialog-headar .note-search-headar .reset-icon {
        width: 23px;
        height: 23px;
        min-height: 23px;
        padding: 0;
        background-color: #f44336 !important; }
        .edit-note-dialog .edit-note-dialog-headar .note-search-headar .reset-icon md-icon {
          color: white;
          font-size: 18px;
          line-height: 1.3;
          margin: auto; }
  .edit-note-dialog .edit-note-text-area {
    background-color: #f7f6f3;
    border: 2px #2196f3 solid !important;
    border-radius: 4px;
    padding-left: 10px; }
  .edit-note-dialog .edit-comment-text-area {
    background-color: #f7f6f3;
    border: 1px #ffc107 solid !important;
    border-radius: 4px;
    padding-left: 10px; }
  .edit-note-dialog .comment-note {
    color: black;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin: 8px;
    border-radius: 4px;
    padding: 4px; }
    .edit-note-dialog .comment-note:hover {
      background-color: #e4e2e2; }
  .edit-note-dialog md-input-container {
    margin-bottom: 0 !important; }
  .edit-note-dialog .edit-btn {
    background-color: #ff5722;
    color: white;
    min-height: unset;
    min-width: unset;
    line-height: unset; }
    .edit-note-dialog .edit-btn md-icon {
      margin: auto; }
  .edit-note-dialog .update-button {
    background-color: #2196f3;
    text-transform: capitalize;
    min-height: unset;
    line-height: unset;
    color: white; }
    .edit-note-dialog .update-button md-icon {
      margin: auto; }
  .edit-note-dialog .comment-update-button {
    background-color: #FF9800;
    text-transform: capitalize;
    min-height: unset;
    line-height: unset;
    color: white; }
    .edit-note-dialog .comment-update-button md-icon {
      margin: auto; }
  .edit-note-dialog .cancel-button {
    background-color: #9e9e9e;
    min-height: unset;
    line-height: unset;
    text-transform: capitalize; }
    .edit-note-dialog .cancel-button md-icon {
      margin: auto; }
  .edit-note-dialog .comm-title {
    font-size: 18px;
    font-weight: 500;
    color: #673ab7; }
  .edit-note-dialog .note-name-bar {
    padding: 10px 10px 0 10px; }
    .edit-note-dialog .note-name-bar .note-name {
      color: #f44336;
      font-weight: 500; }
    .edit-note-dialog .note-name-bar .note-time {
      color: #9e9e9e;
      font-weight: 500; }
  .edit-note-dialog .comment-section .comment-note {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin: 8px;
    border-radius: 4px;
    padding: 4px; }
  .edit-note-dialog .comment-section .edit-comment {
    padding-left: 10px;
    color: #9e9e9e; }
  .edit-note-dialog .comment-section .you-name {
    color: #2196f3; }
  .edit-note-dialog .comment-section .other-name {
    color: #009688; }
  .edit-note-dialog .comment-section .comment-name-bar {
    padding: 0 10px; }
    .edit-note-dialog .comment-section .comment-name-bar .comment-name {
      font-weight: 900;
      text-transform: capitalize; }
      .edit-note-dialog .comment-section .comment-name-bar .comment-name .note-date {
        font-weight: 400;
        font-size: 12px; }
    .edit-note-dialog .comment-section .comment-name-bar .comment-time {
      color: #9e9e9e;
      font-weight: 500; }
    .edit-note-dialog .comment-section .comment-name-bar .edit-time {
      text-transform: lowercase;
      color: #9e9e9e;
      font-weight: 500;
      font-size: 10px; }
  .edit-note-dialog .comment-section .menu-ops md-icon {
    margin: auto;
    color: black; }
  .edit-note-dialog .comment-section .image-section-comment .note-file-name {
    text-decoration: underline;
    padding-left: 10px; }
  .edit-note-dialog .comment-section .image-section-comment md-icon {
    margin: auto;
    margin: 0 10px; }
  .edit-note-dialog .comment-section .image-section-note .note-file-name {
    text-decoration: underline; }
  .edit-note-dialog .comment-section .image-section-note md-icon {
    margin: auto;
    margin: 0 10px; }
  .edit-note-dialog .comment-section .comments-section {
    padding-left: 30px; }
    .edit-note-dialog .comment-section .comments-section md-icon {
      margin: auto;
      color: black; }
    .edit-note-dialog .comment-section .comments-section .comment-user-section {
      color: #ff5722; }
    .edit-note-dialog .comment-section .comments-section .comment-list-section {
      padding: 0 20px; }
      .edit-note-dialog .comment-section .comments-section .comment-list-section .comment-user-name {
        font-size: 12px;
        font-weight: 500; }
      .edit-note-dialog .comment-section .comments-section .comment-list-section .comment-user-date {
        font-size: 10px;
        font-weight: 400; }
      .edit-note-dialog .comment-section .comments-section .comment-list-section .user-comment-text {
        font-size: 14px;
        font-weight: 500;
        padding-left: 10px; }
  .edit-note-dialog .type-comment {
    order: 2;
    border-top: 1px #9e9e9e solid; }
    .edit-note-dialog .type-comment md-icon {
      margin: auto;
      color: #607d8b; }
    .edit-note-dialog .type-comment md-input-container {
      width: 100% !important; }
    .edit-note-dialog .type-comment .note-file-upload {
      cursor: pointer;
      padding-bottom: 30px; }
    .edit-note-dialog .type-comment .send-icon {
      width: 32px;
      height: 32px;
      min-height: 32px;
      padding: 0;
      background-color: #2196f3; }
      .edit-note-dialog .type-comment .send-icon md-icon {
        color: white;
        font-size: 20px;
        line-height: 1.3;
        margin: auto; }
    .edit-note-dialog .type-comment .add-btn {
      padding-bottom: 20px; }
    .edit-note-dialog .type-comment label {
      margin-bottom: 1px;
      margin-left: 6px; }
  .edit-note-dialog .type-comment-note {
    padding: 0 20px; }
    .edit-note-dialog .type-comment-note md-icon {
      margin: auto;
      color: #607d8b; }
    .edit-note-dialog .type-comment-note md-input-container {
      width: 100% !important; }
    .edit-note-dialog .type-comment-note label {
      margin-bottom: 1px;
      margin-left: 6px; }
    .edit-note-dialog .type-comment-note .note-file-upload {
      cursor: pointer;
      padding-bottom: 30px; }
    .edit-note-dialog .type-comment-note .add-btn {
      padding-bottom: 20px; }
    .edit-note-dialog .type-comment-note .comment-icon {
      width: 32px;
      height: 32px;
      min-height: 32px;
      padding: 0;
      background-color: #ff9800; }
      .edit-note-dialog .type-comment-note .comment-icon md-icon {
        color: white;
        font-size: 20px;
        line-height: 1.3;
        margin: auto; }
  .edit-note-dialog .note-error {
    color: black; }
    .edit-note-dialog .note-error md-icon {
      color: red; }

.options-menu md-menu-item {
  height: unset !important;
  min-height: unset !important; }

.view-img-header .view-file-name {
  font-weight: 500;
  font-size: 15px;
  padding: 4px; }

.view-img-header .view-user-name-section {
  padding: 4px; }
  .view-img-header .view-user-name-section .user-name {
    font-weight: 500;
    font-size: 14px; }
  .view-img-header .view-user-name-section .file-upload-date {
    font-weight: 400;
    font-size: 13px; }

.view-img-header .view-header-icons .image-download {
  color: black;
  margin: 0 20px; }

.view-img-header .view-header-icons .close-view-window {
  color: black;
  margin: 0 10px; }

.view-img-header .view-header-icons .view-spacer {
  width: 1px;
  height: 32px;
  background: grey; }

.note-panel-container {
  background-color: white;
  top: 50%;
  bottom: 110px;
  right: 10px;
  left: 72%;
  position: absolute;
  border: 2px #4964ae solid;
  border-radius: 10px;
  height: 300px;
  width: 380px;
  overflow: hidden; }
  .note-panel-container .user-note-form md-input-container {
    margin-bottom: 0; }
    .note-panel-container .user-note-form md-input-container .user-note-box {
      height: 200px !important; }

.atlas-note-btn {
  position: absolute;
  right: 0;
  bottom: 45px; }
  .atlas-note-btn md-icon {
    margin: auto; }
  .atlas-note-btn .note-count {
    top: -1px;
    right: 2px;
    color: #fff;
    background: #3f51b5;
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform .2s ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    width: 30px;
    height: 30px;
    line-height: 30px; }

.user-note-form .file-upload-ip {
  padding: 10px; }
  .user-note-form .file-upload-ip label {
    margin: 0; }
  .user-note-form .file-upload-ip md-input-container {
    margin-bottom: 0; }
    .user-note-form .file-upload-ip md-input-container .md-input {
      border: 1px #4964ae solid !important;
      border-radius: 4px; }
  .user-note-form .file-upload-ip .md-input-focused .md-input {
    box-shadow: 0 0 0 1px #9bd3fe, 0 0 0 1px rgba(29, 155, 209, 0.3); }

.user-note-form .size-check {
  color: red;
  font-weight: 500; }

.user-note-form .upload-file-name {
  font-weight: 500; }

.user-note-form .upload-progress-sec .upload-progress {
  margin: 0;
  height: 24px;
  background-color: transparent;
  border-radius: 8px; }

.user-note-form .upload-progress-sec .upload-progress-bar {
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  background-color: #4CAF50; }

.user-note-form .upload-file-header .upload-file-text {
  font-size: 22px;
  font-weight: 500;
  padding: 6px; }

.user-note-form .upload-file-header .close-upload-dialog {
  padding: 6px; }
  .user-note-form .upload-file-header .close-upload-dialog md-icon {
    margin: auto;
    color: black; }

.user-note-form .preview-img-block {
  margin: 10px;
  border: 2px #03A9F4 solid; }
  .user-note-form .preview-img-block .preview-img {
    width: 480px; }

.user-note-form-img {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .user-note-form-img img {
    height: 100vh;
    transform: translate3d(0px, 0px, 0px) rotate(0deg) scale(0.58572, 0.58572);
    transition: transform 200ms ease 0s; }

.bg-S, .bg-ALL {
  background-color: #3c3c3c !important;
  color: #EEF0F3; }
  .bg-S.off, .bg-ALL.off {
    background-color: grey !important; }
  .bg-S.on, .bg-ALL.on {
    background-color: #3c3c3c !important; }
  .bg-S.bg-S:hover, .bg-ALL.bg-S:hover {
    color: #292B2F; }

.bg-A {
  background-color: #D41F22 !important;
  color: #EEF0F3; }
  .bg-A.off {
    background-color: grey !important; }
  .bg-A.on {
    background-color: #D41F22 !important; }
  .bg-A.bg-A:hover {
    color: #292B2F; }

.bg-M {
  background-color: #00B04F !important;
  color: #EEF0F3; }
  .bg-M.off {
    background-color: grey !important; }
  .bg-M.on {
    background-color: #00B04F !important; }
  .bg-M.bg-M:hover {
    color: #292B2F; }

.bg-I {
  background-color: #313941 !important;
  color: #EEF0F3; }
  .bg-I.off {
    background-color: grey !important; }
  .bg-I.on {
    background-color: #313941 !important; }
  .bg-I.bg-I:hover {
    color: #292B2F; }

.md-sidenav-left {
  z-index: 1001;
  overflow: initial;
  width: fit-content; }
  .md-sidenav-left md-select[disabled] .md-select-value {
    color: white; }
  .md-sidenav-left .md-select-icon {
    display: none; }
  .md-sidenav-left md-select[disabled] .md-select-value {
    border-bottom-color: rgba(0, 0, 0, 0.12);
    text-align: center; }

.menu-content {
  background-color: #4964ae;
  color: #EEF0F3;
  width: auto;
  height: 100vh;
  padding-top: 0;
  display: inline-block; }
  .menu-content .greeting {
    text-transform: capitalize; }
  .menu-content div {
    width: 100%;
    padding: 5px 0; }
  .menu-content md-icon {
    margin: 0 5px;
    color: #EEF0F3; }
  .menu-content md-list-item {
    padding: 0; }
    .menu-content md-list-item div {
      padding: 0;
      width: 15vw;
      height: 48px; }
  .menu-content .navlist a:hover,
  .menu-content .navlist a:focus div {
    text-decoration: none; }
  .menu-content .navlist a:focus md-icon,
  .menu-content .navlist a:hover md-icon,
  .menu-content .navlist a:focus md-icon {
    color: #375cc5; }
  .menu-content .navlist a:hover div,
  .menu-content .navlist a:focus div {
    background-color: #EEF0F3 !important;
    cursor: pointer; }
  .menu-content .navlist a.active .nav-title,
  .menu-content .navlist a:hover .nav-title,
  .menu-content .navlist a.active .nav-title {
    color: #375cc5 !important; }
  .menu-content .navlist .sub-nav {
    padding: 5px 0;
    padding-left: 40px; }
  .menu-content .logo {
    padding: 0; }
    .menu-content .logo h1,
    .menu-content .logo p {
      margin: 0;
      text-align: center; }
    .menu-content .logo h1 {
      font-size: 1.5em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      background-color: #313941;
      padding: 10px 0; }
    .menu-content .logo p,
    .menu-content .logo md-input-container {
      padding: 0;
      background-color: #499FD4;
      color: #EEF0F3;
      text-align: center;
      margin: 0; }
    .menu-content .logo md-select[disabled] .md-select-value {
      color: #EEF0F3;
      background-image: none; }
    .menu-content .logo .md-select-value .md-select-icon {
      display: none; }
  .menu-content .nav-footer img {
    width: 100%;
    height: auto;
    margin-top: 5vh !important; }
  .menu-content .md-menu-bar {
    display: inline-flex; }
  .menu-content .nav-title {
    text-transform: uppercase;
    font-size: 0.95em;
    margin: auto 0;
    padding-right: 10px;
    font-weight: 500; }
  .menu-content .note-button {
    position: absolute;
    bottom: 0;
    left: 5.5vh; }
    .menu-content .note-button .note-btn {
      background-image: linear-gradient(to bottom, #5e6168, #575a60, #505357, #494c4f, #434547); }
      .menu-content .note-button .note-btn:hover {
        background-image: linear-gradient(to right, #d45300, #da5700, #e05a00, #e65e00, #ec6200); }
  .menu-content i {
    font-size: 1.6em;
    margin-right: 10px; }
  .menu-content br {
    display: none; }
  .menu-content md-divider {
    background-color: #EEF0F3; }
  .menu-content a {
    color: #EEF0F3; }
    .menu-content a img {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      margin-right: 10px; }

.system-select {
  color: white !important; }

.note-list span {
  font-size: 16px;
  font-weight: 500; }

.ant-wrapper {
  background-color: #292B2F;
  width: 100%;
  overflow: hidden;
  max-height: 3.5vmin !important;
  /* Move it (define the animation) */ }
  .ant-wrapper md-menu button {
    border-color: none !important; }
  .ant-wrapper .spacer {
    height: 5vh;
    width: 100vw; }
  .ant-wrapper .ant-container {
    margin-top: -5.8px; }
  .ant-wrapper .ant-text-wrapper {
    position: absolute;
    left: 8%;
    z-index: 4 !important;
    padding: 0 15%;
    margin-right: 100px; }
  .ant-wrapper .ant-controls {
    z-index: 10;
    height: 4vh; }
    .ant-wrapper .ant-controls button {
      border-radius: 2px;
      text-align: center;
      height: 100%;
      font-size: 22px !important;
      font-weight: 500;
      border: 1px solid blue !important;
      min-width: 2.4vw; }
    .ant-wrapper .ant-controls .ant-controls-primary {
      padding: 5px 15px; }
      .ant-wrapper .ant-controls .ant-controls-primary button {
        border-radius: 2px;
        height: 3vmin;
        margin: .3vmin 0 0 -100px; }
    .ant-wrapper .ant-controls.closed {
      display: none; }
  .ant-wrapper .ant-clip {
    z-index: 20; }
    .ant-wrapper .ant-clip .ant-controls-secondary {
      height: 5vh; }
      .ant-wrapper .ant-clip .ant-controls-secondary .md-button.md-icon-button {
        margin: 0; }
      .ant-wrapper .ant-clip .ant-controls-secondary .playPause {
        background-color: #4f4f51; }
      .ant-wrapper .ant-clip .ant-controls-secondary button {
        border-radius: 2px;
        text-align: center;
        height: 100%;
        font-size: 22px !important;
        font-weight: 500;
        border: 1px solid blue !important;
        min-width: 2.4vw; }
      .ant-wrapper .ant-clip .ant-controls-secondary i {
        vertical-align: middle; }
  .ant-wrapper .outer {
    position: absolute;
    left: 10vw;
    z-index: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px; }
  .ant-wrapper .left__inner {
    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg);
    display: flex;
    justify-content: flex-end;
    will-change: transform; }
  .ant-wrapper .right__inner {
    padding: 0px 4vmin;
    flex: 1;
    transform: skew(45deg);
    will-change: transform; }
  .ant-wrapper .left__inner,
  .ant-wrapper .right__inner {
    white-space: nowrap; }
    .ant-wrapper .left__inner.off,
    .ant-wrapper .right__inner.off {
      display: none; }
    .ant-wrapper .left__inner.on,
    .ant-wrapper .right__inner.on {
      display: block; }
  .ant-wrapper .left__text,
  .ant-wrapper .right__text {
    transform: skew(-45deg);
    will-change: transform; }
    .ant-wrapper .left__text span,
    .ant-wrapper .right__text span {
      color: white;
      font-size: 1.5em; }
  .ant-wrapper .left__text {
    color: #3c3c3c; }
  .ant-wrapper .right__text {
    color: Goldenrod; }
  .ant-wrapper .ant-text-wrapper {
    height: 5vh;
    position: relative;
    white-space: nowrap; }
  .ant-wrapper .ant-text-wrapper .outer, .ant-wrapper .ant-text-wrapper.single-remove-active .outer {
    height: 100%;
    margin: 0;
    text-align: center;
    /* Starting position */
    -moz-transform: translateX(14.3%);
    -webkit-transform: translateX(14.3%);
    transform: translateX(14.3%);
    /* Apply animation to this element */
    -moz-animation: ant-text-wrapper 280s 0s linear infinite;
    -webkit-animation: ant-text-wrapper 280s 0s linear infinite;
    animation: ant-text-wrapper 280s 0s linear infinite;
    transform: translateZ(0); }

@-moz-keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(14.3%); }
  100% {
    -moz-transform: translateX(-100%); } }

@-webkit-keyframes ant-text-wrapper {
  0% {
    -webkit-transform: translateX(14.3%); }
  100% {
    -webkit-transform: translateX(-100%); } }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(14.3%);
    /* Firefox bug fix */
    -webkit-transform: translateX(14.3%);
    /* Firefox bug fix */
    transform: translateX(14.3%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }
  .ant-wrapper .ant-text-wrapper .single {
    /* Move it (define the animation) */ }
    .ant-wrapper .ant-text-wrapper .single.short {
      -moz-animation: ant-text-wrapper 63s 0s linear infinite;
      -webkit-animation: ant-text-wrapper 63s 0s linear infinite;
      animation: ant-text-wrapper 63s 0s linear infinite;
      transform: translateZ(0); }
      .ant-wrapper .ant-text-wrapper .single.short .left__inner, .ant-wrapper .ant-text-wrapper .single.short .right__inner {
        white-space: nowrap;
        margin: 0 30vw 0 40vw; }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }
    .ant-wrapper .ant-text-wrapper .single.long {
      -moz-animation: ant-text-wrapper 260s 0s linear infinite;
      -webkit-animation: ant-text-wrapper 260s 0s linear infinite;
      animation: ant-text-wrapper 260s 0s linear infinite;
      transform: translateZ(0); }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(100%);
    /* Firefox bug fix */
    transform: translateX(100%); }
  100% {
    -moz-transform: translateX(-100%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-100%);
    /* Firefox bug fix */
    transform: translateX(-100%); } }

@-moz-keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(13%); }
  100% {
    -moz-transform: translateX(-105%); } }

@-webkit-keyframes ant-text-wrapper {
  0% {
    -webkit-transform: translateX(13%); }
  100% {
    -webkit-transform: translateX(-105%); } }

@keyframes ant-text-wrapper {
  0% {
    -moz-transform: translateX(13%);
    /* Firefox bug fix */
    -webkit-transform: translateX(13%);
    /* Firefox bug fix */
    transform: translateX(13%); }
  100% {
    -moz-transform: translateX(-105%);
    /* Firefox bug fix */
    -webkit-transform: translateX(-105%);
    /* Firefox bug fix */
    transform: translateX(-105%); } }

.paused, .outer:hover, .outer.single:hover {
  -webkit-animation-play-state: paused !important;
  -moz-animation-play-state: paused !important;
  -o-animation-play-state: paused !important;
  animation-play-state: paused !important; }

.ant-text-wrapper.closed {
  min-width: 0 !important;
  max-width: 0 !important;
  overflow: hidden !important;
  display: none; }

.ant-clip {
  margin: 0;
  transition: all 1.2s; }

.ant-wrapper {
  transition: all 1.2s;
  transition-delay: 1.2s; }

.sami-icon:focus, .md-icon:focus, span:focus, button:focus {
  outline: none;
  border: 0; }

.ant-clip.closed {
  padding-right: 8.88vw; }
  .ant-clip.closed .ant-controls-secondary {
    display: none !important; }

.ant-wrapper.closed {
  overflow: hidden;
  background: none; }
  .ant-wrapper.closed md-menu {
    opacity: 1; }

.notifications md-button img {
  max-height: 1vw;
  max-width: 2px !important; }

.sami-list {
  background: none;
  padding: 8px 0 0 0; }

.report-popup-dialog {
  width: 60%;
  height: 90%;
  max-height: 100%; }
  .report-popup-dialog .report-app-frame {
    height: 100%; }
  .report-popup-dialog md-icon {
    margin: auto;
    color: black; }
  .report-popup-dialog .report-header {
    background-color: #313941; }
    .report-popup-dialog .report-header .report-close {
      color: white; }
    .report-popup-dialog .report-header .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px; }
  .report-popup-dialog .md-nav-bar .md-button.md-accent {
    color: #4964ae !important; }
  .report-popup-dialog .md-nav-bar md-nav-ink-bar {
    background-color: #4964ae;
    color: #4964ae; }
  .report-popup-dialog .pdf-view {
    background-color: #3f3f3f; }
  .report-popup-dialog .report-week__nav {
    padding-right: 20px; }

* {
  font-family: "Roboto", sans-serif; }

.shell {
  animation: none;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 0;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: none;
  animation-play-state: running;
  animation-timing-function: ease;
  backface-visibility: visible;
  background: 0;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position: 0 0;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-size: auto auto;
  border: 0;
  border-style: none;
  border-width: medium;
  border-color: inherit;
  border-bottom: 0;
  border-bottom-color: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-collapse: separate;
  border-image: none;
  border-left: 0;
  border-left-color: inherit;
  border-left-style: none;
  border-left-width: medium;
  border-radius: 0;
  border-right: 0;
  border-right-color: inherit;
  border-right-style: none;
  border-right-width: medium;
  border-spacing: 0;
  border-top: 0;
  border-top-color: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
  border-top-width: medium;
  bottom: auto;
  box-shadow: none;
  box-sizing: content-box;
  caption-side: top;
  clear: none;
  clip: auto;
  color: inherit;
  columns: auto;
  column-count: auto;
  column-fill: balance;
  column-gap: normal;
  column-rule: medium none currentColor;
  column-rule-color: currentColor;
  column-rule-style: none;
  column-rule-width: none;
  column-span: 1;
  column-width: auto;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  display: inline;
  empty-cells: show;
  float: none;
  font: normal;
  font-family: inherit;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  hyphens: none;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style: none;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  orphans: 0;
  outline: 0;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: none;
  overflow-x: none;
  overflow-y: none;
  padding: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  perspective: none;
  perspective-origin: 50% 50%;
  position: static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes: "“" "”" "‘" "’";
  right: auto;
  tab-size: 8;
  table-layout: auto;
  text-align: inherit;
  text-align-last: auto;
  text-decoration: none;
  text-decoration-color: inherit;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  top: auto;
  transform: none;
  transform-style: flat;
  transition: none;
  transition-delay: 0s;
  transition-duration: 0s;
  transition-property: none;
  transition-timing-function: ease;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 0;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  /* basic modern patch */
  all: initial;
  all: unset; }
  .shell.header {
    width: 100%;
    float: right; }

.leaflet-control-attribution {
  display: none !important; }

/* 1.3 dpr */
.pumplist {
  height: auto;
  min-height: 20vh;
  display: block; }

#myStaticDialog-sc-0,
#myStaticDialog-sc-1,
#myStaticDialog-sc-2 {
  width: 100vw !important;
  height: 100vh !important; }

.md-dialog-container md-dialog#report md-dialog-content {
  padding: 0 20px;
  max-width: 80vw; }

.md-dialog-container md-dialog#report .header-title {
  text-align: right; }

.md-dialog-container md-radio-group {
  margin: 5px 0 0 10px; }

.md-dialog-container .card-heading {
  background-color: #313941;
  position: relative;
  z-index: 99;
  max-height: 12vh; }
  .md-dialog-container .card-heading img {
    width: 4vh;
    height: auto;
    margin-left: 10px; }
  .md-dialog-container .card-heading h2 {
    color: #EEF0F3;
    text-align: center;
    margin: 0;
    font-size: calc(1.2rem + 1.55vmin);
    text-transform: capitalize; }
  .md-dialog-container .card-heading button:hover {
    background-color: #D41F22; }
  .md-dialog-container .card-heading button {
    background-color: #499FD4;
    color: #EEF0F3;
    font-weight: bold;
    font-size: 1.2em;
    float: right;
    margin: 8% 7%; }
  .md-dialog-container .card-heading md-icon {
    margin: auto; }

#pha-141 md-icon {
  display: none; }

.large-chart {
  height: 90vh; }
  .large-chart md-icon {
    display: none; }
  .large-chart md-card {
    height: 90vh;
    max-height: 90vh !important; }
    .large-chart md-card iframe {
      height: 80vh !important;
      max-height: 80vh !important; }

control-card md-card {
  max-height: 40vh; }
  control-card md-card md-input-container {
    margin-right: 20px;
    color: #EEF0F3 !important;
    padding: 0;
    margin: 0;
    float: right; }
    control-card md-card md-input-container md-select .md-select-value {
      height: 100%;
      border: none !important;
      margin-top: 2px;
      text-align: right;
      font-weight: 500;
      color: #EEF0F3; }

.fade-out {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 0; }

.fade-in {
  -webkit-transition: opacity 1.42s;
  /* For Safari 3.1 to 6.0 */
  transition: opacity 1.42s;
  opacity: 1; }

.md-button.md-fab {
  background-color: none;
  widows: 22px; }

.card-info {
  position: absolute;
  top: 0px;
  right: 0px; }
  .card-info i {
    font-size: 1.5em; }

.card-table {
  width: 100%; }

md-card {
  overflow-y: hidden;
  border-radius: 4px; }
  md-card .table-row td {
    text-align: center; }
  md-card .table-row .table-cell-heading {
    text-align: left; }
  md-card md-content {
    border-radius: 4px;
    overflow-y: hidden;
    background-color: white; }
  md-card md-toolbar {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    background-color: #313941;
    color: #eef0f3;
    text-align: center;
    min-height: 40px; }
    md-card md-toolbar h2 {
      margin: 9px 0 0 0; }
      md-card md-toolbar h2.multi-pump-card-title {
        text-align: right;
        padding-right: 4px; }
  md-card .card-heading {
    background-color: #313941; }
  md-card #chart-container {
    width: 100%; }
    md-card #chart-container .custom-controls {
      position: absolute;
      z-index: 1;
      padding-top: 4px;
      margin-left: 19vw; }
      @media screen and (max-width: 1024px) {
        md-card #chart-container .custom-controls {
          margin-left: 12%; } }
      @media screen and (min-width: 1280px) {
        md-card #chart-container .custom-controls {
          margin-left: 17%; } }
      @media screen and (min-width: 1440px) {
        md-card #chart-container .custom-controls {
          margin-left: 19%; } }
      md-card #chart-container .custom-controls .material-icons {
        font-size: 16px; }

.led-box {
  height: 30px;
  width: 25%;
  margin: 0 0 20px 0;
  float: left; }

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 0; }

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px; }

.led-gray {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #a6a6a6;
  border-radius: 50%;
  box-shadow: #e5e5e5 0 -1px 7px 1px, inset #006 0 -1px 9px, #e5e5e5 0 2px 14px; }

body {
  max-width: 100vw;
  overflow: hidden; }

div.pump {
  position: absolute;
  left: 0;
  width: 30%;
  animation: translate 7s infinite linear; }
  div.pump img {
    position: absolute;
    animation: upDown 0.7s alternate infinite ease-in-out;
    width: 5%; }

@keyframes upDown {
  to {
    transform: translatey(50px); } }

@keyframes translate {
  to {
    transform: translatex(450%); } }

.app-container {
  max-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0; }

.header-component {
  color: #EEF0F3;
  position: fixed;
  width: 100%;
  z-index: 70; }
  .header-component .header-row {
    background-color: #4964ae;
    min-height: 43px; }
  .header-component .counter-box.ng-enter {
    transition: 0.8s linear all;
    opacity: 0; }
  .header-component .counter-box-init .counter-box.ng-enter {
    opacity: 1; }
  .header-component .counter-box.ng-enter.ng-enter-active {
    opacity: 1; }
  .header-component .counter-box.ng-leave {
    transition: 0.3s linear all;
    opacity: 1; }
  .header-component .counter-box.ng-leave.ng-leave-active {
    opacity: 0; }
  .header-component .counter-row {
    background-color: #313941;
    border-radius: 0 0 0 4px; }
  .header-component .atlas-menu {
    margin: 0 15px;
    font-size: 26px; }
  .header-component .sys-btn {
    min-width: fit-content;
    margin-right: 0;
    padding: 0;
    color: #aeadad; }
  .header-component .logo {
    margin: 3px 16px 0 0; }
    .header-component .logo h1,
    .header-component .logo p {
      margin: 0;
      text-align: center; }
    .header-component .logo h1 {
      font-size: 1.6em;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      padding: 7px 0;
      float: left;
      margin-left: 2px; }
    .header-component .logo a {
      color: #EEF0F3;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      text-decoration: none !important; }
    .header-component .logo a:hover {
      text-decoration: none !important; }
    .header-component .logo .home-altas {
      font-size: 26px; }
    .header-component .logo .sami-icon {
      margin-left: 5px;
      margin-top: 2px;
      font-weight: 500; }
    .header-component .logo .site-ind-select {
      margin: 0;
      text-align: center; }
      .header-component .logo .site-ind-select md-select .md-select-value {
        text-transform: uppercase;
        font-weight: 500;
        border-bottom-style: none;
        padding: 0; }
      .header-component .logo .site-ind-select .md-select-icon {
        display: none; }
  .header-component .middle-column {
    padding-right: 16px; }
  .header-component .greeting {
    float: left;
    font-weight: 600;
    padding: 0;
    max-height: 43px; }
    .header-component .greeting h5 {
      margin: 14px 0; }
  .header-component .bar-button {
    background-color: #01d8b7;
    color: #eef0f3;
    float: right;
    height: 5px;
    font-size: 9px;
    margin-top: 10px;
    padding: 0;
    /* width: 5%; */
    line-height: 6px;
    min-height: 22px;
    /* text-align: center; */
    text-shadow: 0.5px 0.5px 0.5px #000;
    /* position: relative; */
    margin-left: 5px; }
  .header-component .notifications {
    padding: 0 10px 0 0; }
    .header-component .notifications .header-chat {
      vertical-align: middle;
      margin-right: 5px; }
    .header-component .notifications img {
      cursor: pointer;
      max-width: 32px;
      max-height: 32px;
      padding: 6px;
      margin-top: -4px; }
    .header-component .notifications .counter-img {
      padding: 4px; }
    .header-component .notifications img:hover {
      background-color: #499FD4; }
  .header-component .info-box {
    width: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background-color: #499FD4;
    overflow: hidden;
    padding-top: 9px;
    margin: 0;
    display: inline-block;
    transition: width 0.3s; }
  .header-component .counter-container {
    height: 43px;
    max-height: 43px;
    right: 0;
    float: right;
    width: auto;
    max-height: 43px; }
    .header-component .counter-container:hover {
      border: 1px solid #499FD4; }
      .header-component .counter-container:hover .info-box {
        height: auto;
        width: 120px; }
    .header-component .counter-container .data-box {
      float: right;
      display: inline-block;
      margin: 8px 5px; }
      .header-component .counter-container .data-box span.counter {
        float: left;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        height: auto; }

.metric-box-container .metric-box .value {
  padding: 0 5%;
  font-size: 40px;
  text-align: center;
  width: 10vw; }

.metric-box-container .metric-box .title {
  text-align: center;
  color: #EEF0F3;
  background-color: #499FD4;
  font-weight: bold;
  padding: 0 50%;
  width: 10vw;
  font-size: 23px; }

.demand-container .demand-metrics.left {
  float: left;
  width: 50%; }

.demand-container .demand-metrics.right {
  width: 50%;
  float: right; }

.demand-container .demand-metrics ul {
  padding: 0;
  margin: 0; }

.demand-container .demand-metrics li {
  background-color: #499FD4;
  color: #EEF0F3;
  list-style: none;
  display: block;
  width: 25%;
  text-align: center;
  float: left; }
  .demand-container .demand-metrics li.title {
    background-color: #313941;
    color: #EEF0F3;
    font-weight: bolder;
    float: left; }

.view-container {
  overflow: hidden;
  height: 95vh;
  background-color: #758185;
  max-width: 100%;
  margin: 0;
  padding-top: 0;
  float: left;
  overflow-y: auto; }
  .view-container .content-container {
    max-height: 80vh;
    padding-top: 50px; }

.footer-component {
  background-color: #4964ae;
  color: #EEF0F3;
  width: 100vw;
  height: 5vh;
  text-align: center;
  z-index: 75; }
  .footer-component .logo {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    margin-left: 16px; }
  .footer-component .plug {
    font-size: 12px;
    font-weight: 500; }
  .footer-component span {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em;
    letter-spacing: 0.7px; }
  .footer-component .plug-logo {
    margin-right: 8px;
    font-weight: 500; }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-car .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0); }

.odometer.odometer-auto-theme
.odometer-digit
.odometer-value.odometer-last-value,
.odometer.odometer-theme-car
.odometer-digit
.odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up.odometer-animating
.odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
.odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.odometer.odometer-auto-theme,
.odometer.odometer-theme-car {
  -moz-border-radius: 0.34em;
  -webkit-border-radius: 0.34em;
  border-radius: 0.34em;
  font-family: "Arimo", monospace;
  padding: 0.15em;
  background: #313941;
  color: #EEF0F3; }

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-car .odometer-digit {
  -moz-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 0 0.3em rgba(0, 0, 0, 0.8);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #375cc5), color-stop(40%, #375cc5), color-stop(60%, #499FD4), color-stop(80%, #375cc5), color-stop(100%, #375cc5));
  background-image: -moz-linear-gradient(top, #313941 0%, #313941 40%, #499FD4 60%, #313941 80%, #313941 100%);
  background-image: -webkit-linear-gradient(top, #313941 0%, #313941 40%, #499FD4 60%, #313941 80%, #313941 100%);
  background-image: linear-gradient(to bottom, #499FD4 0%, #499FD4 40%, #313941 10%, #499FD4 80%, #499FD4 100%);
  padding: 0 0.15em; }

.odometer.odometer-auto-theme .odometer-digit:first-child,
.odometer.odometer-theme-car .odometer-digit:first-child {
  -moz-border-radius: 0.2em 0 0 0.2em;
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em 0 0 0.2em; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: 0.15em; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
.odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-car.odometer-animating-down.odometer-animating
.odometer-ribbon-inner {
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear; }

.area {
  animation: blur 0.3s ease-out 1; }

@keyframes blur {
  from {
    text-shadow: 0px 0px 10px #fff, 0px 0px 10px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 25px #fff, 0px 0px 50px #fff, 0px 0px 50px #fff, 0px 0px 50px #7b96b8, 0px 0px 150px #7b96b8, 0px 10px 100px #7b96b8, 0px 10px 100px #7b96b8, 0px 10px 100px #7b96b8, 0px 10px 100px #7b96b8, 0px -10px 100px #7b96b8, 0px -10px 100px #7b96b8; } }

.leaflet-top,
.leaflet-bottom {
  z-index: 75; }

.md-select-menu-container {
  z-index: 1001; }

.sys-eff img {
  width: 3vw;
  height: 3vw;
  margin-left: 5px;
  max-height: 40px;
  max-width: 40px; }

.sys-eff-age img {
  min-width: 55px;
  min-height: 40px; }

.legend {
  text-transform: capitalize; }

.site-select {
  text-transform: uppercase;
  font-weight: 700; }

.site-option {
  text-transform: capitalize; }

.Blink {
  animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate; }

@keyframes blinker {
  from {
    opacity: 0.8; }
  to {
    opacity: 0.2; } }

.text-danger {
  color: red !important;
  font-weight: 500; }

.text-normal {
  color: #1aae1a !important;
  font-weight: 500; }

.onpeak-icon {
  width: 100%;
  position: relative;
  background-color: #313941;
  color: #EEF0F3;
  display: block;
  text-align: right;
  padding-right: 3%;
  font-weight: 500;
  padding: 0.5% 2% 2% 2%; }

.xtick {
  font-size: 10px !important; }

.notification-list {
  position: absolute;
  top: 40px;
  right: 0;
  width: 300px;
  z-index: 1001;
  background-color: #499FD4;
  color: #EEF0F3;
  transition: all 0.1s ease-in-out;
  border-radius: 5px; }

.notification-time {
  display: inline-block;
  margin-left: 11vw;
  font-size: smaller; }

.notification-val {
  margin-left: 3px;
  font-size: 12px;
  position: absolute;
  left: 10px;
  font-weight: 600; }

.circle {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  font-size: 16px;
  text-transform: capitalize;
  color: #EEF0F3;
  line-height: 43px;
  text-align: center;
  background: #000;
  margin: 8px;
  display: inline-block; }

.list-item {
  border-bottom: 2px outset #375cc5; }

.notification-message {
  display: inline-block;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 12px; }

.md-history {
  background-color: #313941; }
  .md-history:hover {
    background-color: #313941 !important;
    color: #EEF0F3; }

.notification-container {
  padding-top: 50px; }
  .notification-container .notification-header span {
    font-size: 1vw;
    font-weight: 500; }
  .notification-container .notification-header md-icon {
    color: #313941; }

.md-close {
  float: right;
  background-color: #CE5656; }
  .md-close:hover {
    background-color: #D41F22 !important;
    color: #EEF0F3; }

.tg-list {
  display: inline-block;
  font-size: 16px;
  position: absolute;
  top: 6px;
  left: -35px; }

.tg-list-item {
  list-style: none; }

.tgl {
  display: none; }
  .tgl, .tgl:after, .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
    box-sizing: border-box; }
    .tgl::selection, .tgl:after::selection, .tgl:before::selection,
    .tgl *::selection,
    .tgl *:after::selection,
    .tgl *:before::selection,
    .tgl + .tgl-btn::selection {
      background: none; }
  .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 10.5em;
    height: 30px;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
    .tgl + .tgl-btn span {
      letter-spacing: 0.5px;
      text-align: center;
      font-weight: bold;
      color: #EEF0F3;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }
    .tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
      position: relative;
      display: block;
      content: "";
      width: 16%;
      height: 100%; }
    .tgl + .tgl-btn:after {
      left: 0; }
    .tgl + .tgl-btn:before {
      display: none; }
  .tgl:checked + .tgl-btn:after {
    left: 85%; }

.tgl-light {
  padding: 0; }
  .tgl-light + .tgl-btn {
    background: #D41F22;
    overflow: hidden;
    border-radius: 0.5em;
    padding: 2px;
    transition: all 0.4s ease; }
    .tgl-light + .tgl-btn span {
      margin-right: 20px;
      margin-top: 4px;
      width: 59%;
      text-align: left;
      font-size: 11px;
      letter-spacing: 0px;
      float: right;
      vertical-align: middle;
      line-height: 9px; }
    .tgl-light + .tgl-btn:after {
      top: 0;
      left: 0;
      width: 15%;
      border-radius: 6px;
      background: #fff;
      transition: all 0.2s ease; }
  .tgl-light:checked + .tgl-btn {
    background: #099f09; }
    .tgl-light:checked + .tgl-btn span {
      margin-left: 5px;
      margin-top: 7px;
      display: block;
      text-align: left; }

.icon-set {
  color: white;
  position: absolute;
  top: 10px;
  left: 10%; }

.icon-report {
  color: white;
  position: absolute;
  top: 10px;
  left: 12%; }

.report-md {
  width: 80vmin;
  height: 80vmin; }
  .report-md .clearfix {
    height: 100%; }
  .report-md object {
    width: 100%;
    height: 100% !important; }

.js-plotly-plot .plotly .modebar {
  z-index: 2 !important; }

.well-info {
  float: right;
  margin: 7px 10px 0 0; }

.status-msg .status-icon {
  margin: 0 0 0 10px; }

.status-msg .more-info .info-btn {
  background-color: white;
  margin-right: 10px;
  color: red;
  min-height: 26px;
  max-height: 30px;
  line-height: inherit; }

md-select .md-select-icon {
  color: #EEF0F3; }

[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer; }

.show-button {
  opacity: 0;
  transition: all 1s linear, opacity 1ms;
  transform: rotate(0deg); }

.login-container {
  height: 95vh;
  padding-top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

@keyframes spinner {
  0% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(359deg); } }
  .login-container * {
    box-sizing: border-box; }
  .login-container .logo-container {
    display: block;
    max-width: 580px;
    min-height: 100px;
    max-height: 100px; }
    .login-container .logo-container .logo {
      width: 290px;
      height: auto;
      margin: 0 auto;
      display: block; }
  .login-container .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%; }
  .login-container .login, .login-container .reset {
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 90%;
    max-width: 450px;
    background: #ffffff;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3); }
    .login-container .login.loading button, .login-container .reset.loading button {
      max-height: 100%;
      padding-top: 50px; }
      .login-container .login.loading button .spinner, .login-container .reset.loading button .spinner {
        opacity: 1;
        top: 40%; }
    .login-container .login.ok button, .login-container .reset.ok button {
      background-color: #8bc34a; }
      .login-container .login.ok button .spinner, .login-container .reset.ok button .spinner {
        border-radius: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        height: 20px;
        animation: none;
        transform: rotateZ(-45deg); }
    .login-container .login input, .login-container .reset input {
      display: block;
      padding: 20px 20px;
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      transition: border-width 0.2s ease;
      border-radius: 2px;
      color: #ccc; }
      .login-container .login input + i.fa, .login-container .reset input + i.fa {
        color: #fff;
        font-size: 1.8em;
        position: absolute;
        margin-top: -70px;
        opacity: 0;
        left: 0;
        transition: all 0.1s ease-in; }
      .login-container .login input:focus, .login-container .reset input:focus {
        outline: none;
        color: #444;
        border-color: #4964ae;
        border-left-width: 40px; }
        .login-container .login input:focus + i.fa, .login-container .reset input:focus + i.fa {
          opacity: 1;
          left: 30px;
          transition: all 0.25s ease-out; }
    .login-container .login a, .login-container .reset a {
      font-size: 0.8em;
      color: #4964ae;
      text-decoration: none; }
    .login-container .login .title, .login-container .reset .title {
      color: #444;
      font-size: 1.2em;
      font-weight: bold;
      margin: 30px 0 40px 0;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
      text-align: center; }
    .login-container .login button, .login-container .reset button {
      width: 100%;
      height: 100%;
      padding: 10px 10px;
      background: #4964ae;
      color: #fff;
      display: block;
      border: none;
      margin-top: 20px;
      position: absolute;
      left: 0;
      bottom: 0;
      max-height: 60px;
      border: 0px solid rgba(0, 0, 0, 0.1);
      border-radius: 0 0 2px 2px;
      transform: rotateZ(0deg);
      transition: all 0.1s ease-out;
      border-bottom-width: 7px; }
      .login-container .login button .spinner, .login-container .reset button .spinner {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        border: 4px solid #ffffff;
        border-top-color: rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        left: 50%;
        top: 0;
        opacity: 0;
        margin-left: -20px;
        margin-top: -20px;
        animation: spinner 0.6s infinite linear;
        transition: top 0.3s 0.3s ease, opacity 0.3s 0.3s ease, border-radius 0.3s ease;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2); }
    .login-container .login:not(.loading) button:hover, .login-container .reset:not(.loading) button:hover {
      box-shadow: 0px 1px 3px #4964ae; }
    .login-container .login:not(.loading) button:focus, .login-container .reset:not(.loading) button:focus {
      border-bottom-width: 4px; }
  .login-container footer {
    display: block;
    padding-top: 15px;
    text-align: center;
    color: #ddd;
    font-weight: normal;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
    font-size: 1.5em; }
    .login-container footer a, .login-container footer a:link {
      display: inline-block;
      width: 45%; }
      .login-container footer a:first-child, .login-container footer a:link:first-child {
        text-align: left; }
      .login-container footer a:last-child, .login-container footer a:link:last-child {
        text-align: right; }
  .login-container .button-title {
    color: white;
    font-size: 24px; }

.ubr-container .leaflet-popup-content {
  text-align: center !important; }

.ubr-container .pop-name {
  text-transform: capitalize;
  font-weight: 600; }

.ubr-container .leaflet-top {
  z-index: 0; }

.ubr-container md-content {
  background-color: #758185; }

.ubr-container .popup_red_color .leaflet-popup-content-wrapper,
.ubr-container .popup_red_color .leaflet-popup-tip {
  background: #b60909; }
  .ubr-container .popup_red_color .leaflet-popup-content-wrapper a,
  .ubr-container .popup_red_color .leaflet-popup-tip a {
    color: white !important;
    font-weight: 500; }

.ubr-container .popup_green_color .leaflet-popup-content-wrapper,
.ubr-container .popup_green_color .leaflet-popup-tip {
  background: green; }
  .ubr-container .popup_green_color .leaflet-popup-content-wrapper a,
  .ubr-container .popup_green_color .leaflet-popup-tip a {
    color: white !important;
    font-weight: 500; }

.ubr-container .popup_grey_color .leaflet-popup-content-wrapper,
.ubr-container .popup_grey_color .leaflet-popup-tip {
  background: #758185; }
  .ubr-container .popup_grey_color .leaflet-popup-content-wrapper a,
  .ubr-container .popup_grey_color .leaflet-popup-tip a {
    color: #EEF0F3 !important;
    font-weight: 500; }

.ubr-container .map-block .map-card-1 {
  min-height: 65vh;
  overflow: hidden; }

.ubr-container .map-block .map-card-2 {
  min-height: 48vh;
  overflow: hidden; }

.ubr-container .station-check {
  position: absolute;
  right: 4px;
  z-index: 1;
  overflow-y: scroll;
  width: 10%; }
  .ubr-container .station-check .system-check-header {
    padding: 2px 0;
    background-color: #313941;
    color: #EEF0F3;
    font-weight: 500;
    font-size: calc(1rem + 1.45vmin);
    text-transform: uppercase; }
    .ubr-container .station-check .system-check-header a {
      color: white;
      text-decoration: none; }
  .ubr-container .station-check .site-grey {
    background-color: grey; }
  .ubr-container .station-check .site-green {
    background-color: #097a09; }
  .ubr-container .station-check .site-red {
    background-color: #b60909; }
  .ubr-container .station-check .check-name {
    width: 100%; }
    .ubr-container .station-check .check-name .check-btn {
      width: stretch;
      -webkit-transition: ease-out 0.5s;
      -moz-transition: ease-out 0.5s;
      transition: ease-out 0.5s;
      box-shadow: inset 0 0 0 0 #2E3F6E; }
    .ubr-container .station-check .check-name .ADD {
      box-shadow: inset 0 0 0 50px green; }
    .ubr-container .station-check .check-name .DELETE {
      box-shadow: inset 0 0 0 50px red; }

@media (max-width: 1279px) and (min-width: 960px) {
  .ubr-container .station-check {
    width: 20%; } }

@media (max-width: 959px) and (min-width: 600px) {
  .ubr-container .station-check {
    width: 20%; } }

@media (max-width: 599px) {
  .ubr-container .station-check {
    width: 30%; } }

.ubr-container .ubr-analysis {
  overflow: visible; }
  .ubr-container .ubr-analysis .ubr-analysis-block {
    margin-top: 50px; }
    .ubr-container .ubr-analysis .ubr-analysis-block img {
      height: 15px;
      width: 40px;
      vertical-align: baseline; }
    .ubr-container .ubr-analysis .ubr-analysis-block .ubr-param-name {
      padding-left: 4px; }

.ubr-container .ubr-card-div {
  padding: 0 6px;
  background-color: gainsboro; }

.ubr-container .uber-value-block {
  text-align: center; }
  .ubr-container .uber-value-block .ubr-counter-value {
    font-weight: 500; }
  .ubr-container .uber-value-block .counter-value-green {
    color: green; }
  .ubr-container .uber-value-block .counter-value-red {
    color: red; }
  .ubr-container .uber-value-block .counter-value-grey {
    color: #758185; }

.ubr-container .ub-0 {
  background-color: #2E3F6E; }

.ubr-container .ub-1 {
  background-color: #2E3F6E; }

.ubr-container .ub-2 {
  background-color: #2E3F6E; }

.ubr-container .ub-3 {
  background-color: #2E3F6E; }

.ubr-container .ub-4 {
  background-color: #2E3F6E; }

.ubr-container .ub-5 {
  background-color: #2E3F6E; }

.ubr-container .ubr-param {
  color: #EEF0F3;
  padding: 2px 0;
  font-size: calc(0.45rem + 1.25vmin);
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.5px; }

.ubr-container .ubr-unit {
  color: #EEF0F3;
  margin: 0;
  padding: 2px 0;
  font-size: calc(0.45rem + 1.25vmin);
  letter-spacing: 0.5px;
  font-weight: 500; }
  .ubr-container .ubr-unit md-switch {
    margin: 0; }

.ubr-container .flow-unit {
  text-align: center;
  margin: 0;
  padding: 2px 0;
  font-size: calc(0.45rem + 1.25vmin);
  font-weight: bolder;
  display: inline-flex;
  text-align: center;
  letter-spacing: 0.5px; }
  .ubr-container .flow-unit md-switch {
    margin: 0;
    width: fit-content;
    padding: 0 5px;
    height: fit-content; }
  .ubr-container .flow-unit md-switch.md-checked.md-warn .md-thumb {
    background-color: #499FD4; }
  .ubr-container .flow-unit md-switch.md-checked.md-warn .md-bar {
    background-color: rgba(73, 159, 212, 0.5); }
  .ubr-container .flow-unit :focus {
    outline: none; }

.ubr-container .ubr-short-links {
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  background-color: gainsboro; }
  .ubr-container .ubr-short-links a {
    color: #2E3F6E; }

.ubr-container .ubr-value {
  font-size: calc(1.4rem + 1.8vw);
  letter-spacing: 1px; }

.ubr-container .ubr-value-0 {
  font-size: calc(1.4rem + 1.7vw);
  letter-spacing: 1px; }

.ubr-container .ubr-value-1 {
  font-size: calc(1rem + 1.5vw);
  letter-spacing: 1px; }

.ubr-container .ubr-gauge span {
  line-height: 140px !important;
  font-size: 38px;
  font-weight: 500; }

.ubr-container .ubr-gauge u {
  font-size: 20px !important;
  font-weight: 500 !important; }

.ubr-container .ubr-gauge b {
  line-height: 85px !important;
  font-weight: 500 !important;
  text-transform: uppercase; }

.ubr-container canvas {
  height: 170px;
  width: 170px;
  overflow: hidden; }

.ubr-container .ubr-card-gauge {
  max-height: 117.36px;
  position: relative; }

.ubr-container .ubr-site-bar {
  padding: 2px 0;
  background-color: #313941;
  color: #EEF0F3;
  font-weight: 500;
  font-size: calc(1rem + 1.45vmin);
  text-transform: uppercase; }
  .ubr-container .ubr-site-bar a {
    color: white;
    text-decoration: none; }

.ubr-container .ubr-site-bar-grey {
  background-color: grey; }

.ubr-container .ubr-site-bar-green {
  background-color: #097a09; }

.ubr-container .ubr-site-bar-red {
  background-color: #b60909; }

.ubr-container .unit-symbol {
  font-size: calc(0.45rem + 1.3vmin); }

.ubr-container md-icon {
  vertical-align: text-top;
  color: red;
  margin: 0; }

.ubr-container .good-start {
  position: absolute;
  font-weight: 500;
  font-size: calc(0.4rem + 8px);
  z-index: 10;
  bottom: 0;
  left: 4px; }

.ubr-container .good-stop {
  z-index: 10;
  font-weight: 500;
  font-size: calc(0.4rem + 8px);
  position: absolute;
  bottom: 0;
  right: 4px; }

.ubr-container .radar-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 167px;
  margin: 0;
  color: #EEF0F3 !important;
  background-color: #375cc5 !important;
  border-radius: 2px;
  z-index: 0; }
  .ubr-container .radar-button:hover {
    background-color: #01584A !important; }

.ubr-dialog {
  padding: 4px;
  background-image: linear-gradient(to right top, #e6e8ee, #e6e7ec, #e6e6e9, #e6e6e7, #e5e5e5); }
  .ubr-dialog .ubr-text {
    font-size: 16px;
    font-weight: 500; }
    .ubr-dialog .ubr-text .add {
      color: green; }
    .ubr-dialog .ubr-text .delete {
      color: red; }
  .ubr-dialog .text-top {
    padding-bottom: 10px; }
  .ubr-dialog .ubr-close {
    background-color: #D41F22;
    transition: all 0.2s ease-in-out;
    color: #EEF0F3; }
    .ubr-dialog .ubr-close:hover {
      background-color: #D41F22;
      box-shadow: 0 5px 15px #d41f22; }
  .ubr-dialog .ubr-update {
    background-color: #4964ae;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    color: #EEF0F3; }
    .ubr-dialog .ubr-update:hover {
      background-color: #4964ae;
      box-shadow: 0 5px 15px #4964ae; }
  .ubr-dialog md-select {
    padding: 10px 0;
    margin: 0; }
    .ubr-dialog md-select .md-text {
      text-transform: uppercase; }
  .ubr-dialog hr {
    margin: 10px; }

.popup-dialog {
  width: 85% !important;
  height: 90% !important; }
  .popup-dialog .popup-dialog-headar {
    height: 50px;
    background-color: #313941; }
    .popup-dialog .popup-dialog-headar .header-text {
      font-size: 20px;
      font-weight: 500; }
    .popup-dialog .popup-dialog-headar .popup-dialog-icon img {
      margin: 5px;
      max-height: 40px;
      max-width: 40px; }
  .popup-dialog .map-radar {
    height: 100%; }
  .popup-dialog .popup-dialog-close {
    background-color: #499FD4;
    color: #EEF0F3;
    font-weight: 500;
    font-size: 1em;
    float: right; }
    .popup-dialog .popup-dialog-close:hover {
      background-color: #D41F22 !important; }

.notification-container .pagination-table {
  color: white !important;
  background-color: #000 !important;
  font-size: 16px;
  height: 40px; }
  .notification-container .pagination-table md-icon {
    margin: 0;
    color: white; }
  .notification-container .pagination-table md-select.md-table-select {
    margin: 20px 0 26px 0; }
  .notification-container .pagination-table .md-button[disabled] md-icon {
    color: red !important; }

.notification-container md-select.md-table-select > .md-select-value > span > .md-text {
  color: #EEF0F3;
  font-weight: 500; }

.notification-container table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
  background-color: #e6e5e5 !important; }

.notification-container table.md-table tbody.md-body > tr.md-row > td {
  font-weight: 500;
  text-transform: capitalize; }

.notification-container table.md-table th.md-column {
  color: #292B2F;
  font-size: 16px;
  text-transform: uppercase; }

.notification-container .no-results {
  padding: 0 16px; }

.notification-container .analysis-chart iframe {
  height: 60vh; }

.notification-container .notification-header {
  background-color: #000;
  color: white;
  height: 40px; }
  .notification-container .notification-header .expand-icon {
    margin-left: 10px; }
  .notification-container .notification-header .notification-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: .2px;
    margin-left: 5px; }

.notification-container .catalog-icon {
  margin-right: 10px; }

.notification-container md-content {
  background: none; }

.operating-container .icon-expand {
  display: none; }

.pump_health_analytics-container,
.pump-health-container {
  width: 100%; }
  .pump_health_analytics-container #pha-14 md-card,
  .pump-health-container #pha-14 md-card {
    height: auto; }
  .pump_health_analytics-container md-card,
  .pump-health-container md-card {
    max-height: 50vh; }
    .pump_health_analytics-container md-card table,
    .pump-health-container md-card table {
      width: 100%; }
    .pump_health_analytics-container md-card md-toolbar,
    .pump-health-container md-card md-toolbar {
      text-align: center; }

table.pvtTable tr th {
  background-color: #cdcdcd;
  border: 1px solid #000;
  font-size: 8pt;
  padding: 5px;
  font-weight: 500; }

.pvtVal {
  font-size: 14px !important; }

md-icon {
  margin: 8px 0 0 8px; }

.pscardTitle {
  font-size: 1.0em !important;
  text-align: center !important;
  max-width: 100% !important; }

.table {
  margin-bottom: 1px !important; }

table > thead > tr > th {
  text-align: center; }

.pump-row {
  text-transform: uppercase; }

.realtime-container {
  overflow-y: scroll;
  width: 100%; }
  .realtime-container md-card {
    overflow: hidden;
    max-height: 50vh;
    height: 38.5vh; }
    .realtime-container md-card md-card-actions button {
      padding-top: 19px; }
    .realtime-container md-card md-toolbar {
      text-align: center; }

.pump-name {
  text-transform: uppercase; }

.md-select-menu-container.md-active md-select-menu {
  text-transform: uppercase; }

.onpeak-expand {
  width: 100%;
  background-color: #499FD4;
  text-transform: uppercase;
  font-weight: 12px;
  color: white;
  display: block;
  text-align: right;
  padding-right: 2%; }

#chart-container table:nth-Child(3) {
  display: none; }

.setting-container {
  width: 100%;
  padding-top: 50px; }
  .setting-container md-content {
    background: none; }
  .setting-container md-card {
    padding: 0 !important;
    box-shadow: 0px 0px 0px; }
    .setting-container md-card a.md-button.md-primary.md-raised,
    .setting-container md-card a.md-button.md-primary.md-fab,
    .setting-container md-card .md-button.md-primary.md-raised,
    .setting-container md-card .md-button.md-primary.md-fab {
      background-color: #D17E3E;
      float: right; }
    .setting-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
    .setting-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
    .setting-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
    .setting-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
      background-color: #E55921; }
    .setting-container md-card md-card-content {
      padding: 0;
      width: 100%; }
      .setting-container md-card md-card-content .alert-header {
        height: 40px;
        background-color: #000;
        color: white; }
        .setting-container md-card md-card-content .alert-header .alert-name {
          font-size: 20px;
          text-transform: uppercase;
          letter-spacing: 0.2px;
          font-weight: 500; }
  .setting-container .process-name {
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    font-weight: 500;
    vertical-align: middle;
    line-height: 3; }
  .setting-container .card-title {
    background-color: #313941 !important; }
  .setting-container .card-out {
    background-color: white; }
  .setting-container md-checkbox.md-checked .md-icon {
    background-color: #375cc5; }
  .setting-container md-select .md-select-value {
    text-align: center; }
  .setting-container .card-height {
    max-height: 40vh;
    overflow-y: scroll; }
  .setting-container .icon-md {
    margin-top: 30px;
    font-size: 18px !important;
    margin-left: 10px; }
  .setting-container md-checkbox[disabled].md-checked .md-icon {
    background-color: #375cc5; }
  .setting-container md-input-container.md-block {
    text-transform: capitalize; }
  .setting-container .alert-catalog {
    padding: 0; }
    .setting-container .alert-catalog .alert-catalog-header {
      height: 40px;
      background-color: #000;
      color: white; }
      .setting-container .alert-catalog .alert-catalog-header .alert-catalog-title {
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: 500; }
      .setting-container .alert-catalog .alert-catalog-header .download-csv {
        padding-right: 10px; }
    .setting-container .alert-catalog table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5 !important; }
    .setting-container .alert-catalog table.md-table tbody.md-body > tr.md-row > td {
      font-weight: 500; }
    .setting-container .alert-catalog table.md-table:not(.md-row-select) td.md-cell:first-child,
    .setting-container .alert-catalog td.md-cell:last-child {
      text-transform: capitalize; }
    .setting-container .alert-catalog table.md-table th.md-column {
      color: #292B2F;
      font-size: 16px;
      text-transform: uppercase; }
  .setting-container .daily-check {
    line-height: 2.8;
    font-size: 18px;
    font-weight: 500; }

synchronize-chart-container, .sc-container {
  width: 100%; }
  synchronize-chart-container md-card, .sc-container md-card {
    min-height: 45vw; }
    synchronize-chart-container md-card md-toolbar, .sc-container md-card md-toolbar {
      background-color: #292B2F;
      text-align: center;
      text-transform: capitalize; }

.ss-container {
  width: 100%;
  padding-top: 50px; }
  .ss-container md-radio-group {
    margin: 5px 0 0 15px; }
  .ss-container md-card {
    padding: 0 !important; }
    .ss-container md-card md-input-container.md-block {
      margin: 0; }
      .ss-container md-card md-input-container.md-block md-select {
        width: 6vw; }
    .ss-container md-card.table-container {
      padding: 0;
      overflow: hidden; }
      .ss-container md-card.table-container td.table-cell-heading {
        font-weight: bolder; }
        .ss-container md-card.table-container td.table-cell-heading span {
          text-transform: none; }
    .ss-container md-card a.md-button.md-primary.md-raised,
    .ss-container md-card a.md-button.md-primary.md-fab,
    .ss-container md-card .md-button.md-primary.md-raised,
    .ss-container md-card .md-button.md-primary.md-fab {
      background-color: #D17E3E; }
    .ss-container md-card a.md-button.md-primary.md-raised:not([disabled]):hover,
    .ss-container md-card a.md-button.md-primary.md-fab:not([disabled]):hover,
    .ss-container md-card .md-button.md-primary.md-raised:not([disabled]):hover,
    .ss-container md-card .md-button.md-primary.md-fab:not([disabled]):hover {
      background-color: #E55921; }
    .ss-container md-card md-card-content.content-table {
      padding: 0;
      width: 100%; }
    .ss-container md-card .table-heading,
    .ss-container md-card tbody td {
      text-transform: capitalize; }
    .ss-container md-card md-toolbar {
      text-align: center;
      text-transform: capitalize; }

.frame3 md-radio-group md-radio-button checked .md-ink-ripple {
  color: #878628; }

.frame3 md-radio-group md-radio-button .md-off {
  border-color: #878628; }

.wha-container {
  width: 100%; }
  .wha-container #chart-wha1 md-card md-card-content {
    padding: 0; }
  .wha-container #chart-wha1 {
    padding-bottom: 10px; }
    .wha-container #chart-wha1 md-content {
      overflow-y: visible; }
  .wha-container md-card md-toolbar {
    text-align: center; }
  .wha-container md-card table.table {
    margin-bottom: 0; }
    .wha-container md-card table.table .table-heading {
      text-transform: capitalize;
      text-align: center !important; }
      .wha-container md-card table.table .table-heading span.unit {
        text-transform: lowercase; }
    .wha-container md-card table.table tr {
      text-align: center; }

.stats-table {
  font-weight: 500; }
  .stats-table table.md-table th.md-column,
  .stats-table table.md-table td.md-cell {
    text-align: center !important;
    padding: 0 !important;
    font-size: 14px !important; }
  .stats-table table.md-table tbody.md-body > tr.md-row {
    height: 30px !important;
    text-align: center !important; }
  .stats-table table.md-table thead.md-head > tr.md-row {
    height: 30px !important; }
